import React from "react";
import { Drawer } from "../components/Drawer";
import { Title } from "../components/Title";
import { Fallback } from "../components/fallback";
import { getUbicacion,getBusinessIdentity } from "../queries/Queries.js";
import { url,urlRestAPI } from "../queries/URL";
import "../stylesheets/dist/main.css";
import "../stylesheets/dist/ubicacion.css";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Loading } from "../components/Loading.js";
import { Refresh } from "../components/Refresh";
import { Inicio } from "./Inicio";
/**
 * Regresa el componente de la pantalla UBICACIÓN
 * @param  {object}   data    Guarda la información recibida por la petición
 * @return {Component}        Componente de la pantalla UBICACIÓN
 */
 var n=0;
 var x; 
export class Ubicacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      refresh:undefined
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.method = new Inicio();
  }

  /**
   * Hace la petición y guarda la respuesta en data
   * Restablece los valores por defecto de la pantalla MODELOS
   */
  componentDidMount() {
  //  var data2 = getBusinessIdentity(this.props.match.params.desarrollo);
 
 
    localStorage.setItem("torre", 0);
    localStorage.setItem("prototipo", 0);
    localStorage.setItem("tab", 0);

    const requestOptions = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/ubicacion",requestOptions)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
      
        this.setState({
          data: data,
        });
      }
      });
      
    /*var data = getUbicacion(this.props.match.params.desarrollo);
    data.then((res) => {
      this.setState({
        data: res.data.data,
      });
    });*/


    const requestOptions2 = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/menu",requestOptions2)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data2: data.desarrollos[0],
      });
    }
      });
    /*data2.then((res) => {
      //console.log(res.data.data.desarrollos[0].identidad.Background.url)
      this.setState({
        data2: res.data.data.desarrollos[0],
      });
    });*/
  
  }

  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
    console.log('stopped')
   /* if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    clearInterval(x); 
    var n=0;
    setTimeout(() => {
      x = setInterval(function(){
        console.log(n);
        if (n==30) {
          location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    }, 1000);
  }

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }*/
  this.method.changeScreen();
  
  }


  render() {
    let datos = this.state.data;
    let refresh =this.state.refresh;
    console.log(refresh);
    if (refresh == false) {
      return <Refresh />
    }
    if(datos === undefined){
        return <Loading />
    }
    try {
      return (
        <div onClick={this.changeScreen}>
          <Drawer data={this.state.data2} />
          <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />
          <Title
            title={this.state.data.desarrollos[0].ubicacion.Titulo}
            text={this.state.data.desarrollos[0].ubicacion.Descripcion}
            items={this.state.data.desarrollos[0].ubicacion.Caracteristicas}
          />
          <Lugar
            heroImg={url + this.state.data.desarrollos[0].ubicacion.Imagen.url}
          />
        </div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}

/**
 * Regresa el componente de la ubicación con el circulo de background
 * @param  {url}        heroImg    Url de la imagen de la ubicación
 * @return {Component}             Imagen de la ubicación
 */
let fill = null;
const Lugar = ({ heroImg }) => {
  if (localStorage.getItem("colorTerciario").includes('')) {
    fill=<div id="back" style={{backgroundColor: localStorage.getItem("colorMenu")}}>
    <img src={heroImg} alt="ubicacion" />
  </div>;
  }else{
    fill=<div id="back" style={{backgroundColor: localStorage.getItem("colorTerciario")}}>
    <img src={heroImg} alt="ubicacion" />
  </div>;
  }
  return (
    <div className="lugar">
      {fill}
    </div>
  );
};
