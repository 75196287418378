import React, { createRef } from "react";
import ImageGallery from "react-image-gallery";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { JustImage } from "./JustImage";
import {
  LeftNav,
  RightNav,
  Minimize,
  FullScreen,
  Times,
} from "../components/Icons";
import { Pannellum } from "pannellum-react";
import { Legal } from "../components/Legal";
import { url } from "../queries/URL";
import { image, video, r3d, v360 } from "../queries/Consts";
import "../stylesheets/dist/image-gallery.css";
import "../stylesheets/dist/video-react.css";
import "../stylesheets/dist/responsive.css";
/**
 * Regresa el componente de las galerías mostrado en AMENIDADES, MULTIMEDIA Y MULTIMEDIA-DEPARTAMENTO
 * @param  {Number}   index   Indice de la imagen en la cual se encuentra la Galería
 * @param  {Array}    arr     Arreglo de imágenes tal cual vienen desde el panel y se utiliza para thumbnails
 * @param  {Array}    tmp     Arreglo con las imágenes procesadas para la utilización dentro del componente galería y se utiliza para el modo fullscreen
 * @param  {Boolean}  full    Estado de la pantalla fullscreen(no total)
 * @param  {Boolean}  fullcustom    Estado de la pantalla fullscreen(total-completo)
 * @return {Component}        Galería con todos los archivos y sus thumbnails
 */
export class GaleriaCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      arr: [],
      tmp: [],
      full: false,
      fullcustom: false,
    };

    this.myrenderFullscreenButton = this.myrenderFullscreenButton.bind(this);
    this.myrenderLeftNav = this.myrenderLeftNav.bind(this);
    this.myrenderRightNav = this.myrenderRightNav.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.handleLeft = this.handleLeft.bind(this);
    this.handleRight = this.handleRight.bind(this);
    this._myImageGalleryRenderer = this._myImageGalleryRenderer.bind(this);
    this.exitHandler = this.exitHandler.bind(this);
    this.myrenderLeftNav = this.myrenderLeftNav.bind(this);
    this.myrenderRightNav = this.myrenderRightNav.bind(this);
    this.fullscreen = createRef();
  }

  componentDidMount() {
    //Detecta el click en las imágenes para pasar al modo fullscreen
    document.body.addEventListener("click", (event) => {
      if (
        event.target.tagName === "IMG" &&
        event.target.className === "image-gallery-image"
      ) {
        this.handleFullscreen();
      }
    });

    //Detecta la salida del modo fullscreen con la tecla ESC y lo procesa
    window.addEventListener("keydown", (event) => {
      try {
        // eslint-disable-next-line
        if (event.key == "Escape") {
          this.handleClose();
        }
      } catch (error) {
        console.error(error);
      }
    });

    document.addEventListener("fullscreenchange", this.exitHandler);
    document.addEventListener("webkitfullscreenchange", this.exitHandler);
    document.addEventListener("mozfullscreenchange", this.exitHandler);
    document.addEventListener("MSFullscreenChange", this.exitHandler);

    try {
      for (let index = 0; index < this.props.data.length; index++) {
        // Arreglo que vamos usar para los thumbnails
        this.setState((prev) => ({
          arr: [...prev.arr, this.props.data[index]],
        }));

        let aux = {};
        aux.original =
          this.props.data[index].Tipo === r3d
            ?  this.props.data[index].Url
            : url + this.props.data[index].Archivo.url;
        aux.thumbnail = url + this.props.data[index].Thumbnail.url;
        aux.description = this.props.data[index].Descripcion;
        aux.type = this.props.data[index].Tipo;

        // Arreglo para los archivos en modo fullscreen
        this.setState((prev) => ({
          tmp: [...prev.tmp, aux],
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Si no estas en fullscreen hace el cierre de los componentes
   */
  exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      this.handleClose();
    }
  }

  /**
   * Maneja los errores que provienen de los archivos del arreglo tmp
   */
  _handleImageError(event) {
    if (
      this.props.defaultImage &&
      event.target.src.indexOf(this.props.defaultImage) === -1
    ) {
      event.target.src = this.props.defaultImage;
    }
  }

  /**
   * Renderiza la galería dependiendo el tipo del archivo y en que modo de fullscreen se encuentra
   */
  _myImageGalleryRenderer(item) {
    const onImageError = this._handleImageError;

    // Cambia el color de fondo dependiendo el modo de fullscreen en el que estes
    if (this.state.fullcustom) {
      try {
        var element = document.getElementsByClassName(
          "image-gallery-slides"
          )[0];
          element.id = "full";
          document.getElementById("full").style.background = "black";
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          document.getElementById("full").style.background = "";
        } catch (error) {
          // console.error(error)
        }
      }

    // Modo fullscreen(pantalla completa)
    if (this.state.fullcustom) {
      // Caso si el archivo es un video
      if (item.type === video) {
        console.log(url + item.Archivo.url);
        return (
          <div style={{ position: `relative`, margin: `90px 10vw` }}>
            <video width="1517" height="900" poster={item.Thumbnail} controls>
              <source src={item.original} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      }

      // Caso si el archivo es un recorrido(URL)
      if (item.type === r3d) {
        return (
          <div style={{ position: `relative`, margin: `45px 4vw` }}>
            <iframe
              src={item.original}
              title="W3Schools Free Online Web Tutorials"
              style={{ width: `80vw`, height: `1000px` }}
            ></iframe>
          </div>
        );
      }

      // Caso para vistas 360
      if (item.type === v360) {
        
        return (
          <div
            style={{ position: `relative`, margin: `40px 10vw` }}
            key={item.original}
          >
            <Pannellum
              width="80vw"
              height="1000px"
              image={item.original}
              pitch={10}
              yaw={180}
              hfov={110}
              autoLoad
              showZoomCtrl={false}
              showFullscreenCtrl={false}
              onLoad={() => {
                console.log("panorama loaded");
              }}
            />
            {console.log(item.original)}
          </div>
        );
      }

      // Caso para imágenes
      if (item.type === image) {
        return (
          <div style={{ position: `relative`, margin: `40px 10vw` }}>
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={item.original}
                  alt={item.originalAlt}
                  srcSet={item.srcSet}
                  sizes={item.sizes}
                  onLoad={this.props.onImageLoad}
                  onError={onImageError.bind(this)}
                  // className='image-gallery-image'
                  style={{ width: `80vw`, height: `1000px` }}
                />
              </TransformComponent>
            </TransformWrapper>

            {item.title && (
              <span className="description">
                {item.title}
                {item.link && item.linkLabel && (
                  <a className="my-own-class" href={item.link}>
                    {item.linkLabel}
                  </a>
                )}
              </span>
            )}
          </div>
        );
      }
    } else {
      // Caso para videos(fullscreen no total)
      if (item.type === video) {
        return (
          <div style={{ position: `relative`, margin: `138px 15vw` }}>
            <video controls style={{ width: `100%`, height: `804px` }}>
              <source src={item.original} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {item.description && (
              <span className="description">
                {item.description}
                {item.link && item.linkLabel && (
                  <a className="my-own-class" href={item.link}>
                    {item.linkLabel}
                  </a>
                )}
              </span>
            )}
          </div>
        );
      }

      // Caso para recorridos(fullscreen no total)
      if (item.type === r3d) {
        console.log(item.original);
        return (
          <div style={{ position: `relative`, margin: `138px 15vw` }}>
            {console.log(item)}
            <iframe
              src={item.original}
              title="W3Schools Free Online Web Tutorials"
              style={{ width: `70vw`, height: `804px` }}
            ></iframe>
          </div>
        );
      }

      // Caso para vistas 360(fullscreen no total)
      if (item.type === v360) {
        console.log(item.original);
        return (
          <div
            style={{ position: `relative`, margin: `138px 15vw` }}
            key={item.original}
          >
            <Pannellum
              width="70vw"
              height="804px"
              image={item.original}
              pitch={10}
              yaw={180}
              hfov={110}
              autoLoad
              showZoomCtrl={false}
              showFullscreenCtrl={false}
              onLoad={() => {
                console.log("panorama loaded");
              }}
            />

            {item.description && (
              <span className="description">
                {item.description}
                {item.link && item.linkLabel && (
                  <a className="my-own-class" href={item.link}>
                    {item.linkLabel}
                  </a>
                )}
              </span>
            )}
          </div>
        );
      }

      // Caso para imágenes(fullscreen no total)
      if (item.type === image) {
        return (
          <div style={{ position: `relative`, margin: `138px 15vw` }}>
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={item.original}
                  alt={item.originalAlt}
                  srcSet={item.srcSet}
                  sizes={item.sizes}
                  onLoad={this.props.onImageLoad}
                  onError={onImageError.bind(this)}
                  // className='image-gallery-image'
                  //style={{ width: `70vw`, height: `804px` }}
                />
              </TransformComponent>
            </TransformWrapper>

            {item.description && (
              <span className="description">
                {item.description}
                {item.link && item.linkLabel && (
                  <a className="my-own-class" href={item.link}>
                    {item.linkLabel}
                  </a>
                )}
              </span>
            )}
          </div>
        );
      }
    }

    // Caso por defecto por si no es de ningún tipo de archivos.
    return (
      <div>
        <img
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          onLoad={this.props.onImageLoad}
          onError={onImageError.bind(this)}
          // className='image-gallery-image'
        />

        {item.Description && (
          <span className="image-gallery-description">
            {item.Description}
            {item.link && item.linkLabel && (
              <a className="my-own-class" href={item.link}>
                {item.linkLabel}
              </a>
            )}
          </span>
        )}
      </div>
    );
  }

  /**
   * Maneja la entrada al modo fullscreen(no total)
   */
  handleFullscreen(e, position) {
    try {
      this.setState(
        {
          index: position,
          full: true,
        },
        () => {
          this.fullscreen.current.fullScreen();
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Pausa los videos cuando nos movemos a la izquierda
   */
  handleLeft() {
    try {
      for (
        let index = 0;
        index < document.getElementsByTagName("video").length;
        index++
      ) {
        document.getElementsByTagName("video")[index].pause();
      }
      return true;
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  /**
   * Pausa los videos cuando nos movemos a la derecha
   */
  handleRight() {
    try {
      for (
        let index = 0;
        index < document.getElementsByTagName("video").length;
        index++
      ) {
        document.getElementsByTagName("video")[index].pause();
      }
      return true;
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  /**
   * Renderiza nuestro botón izquierdo de nuestra galería
   */
  myrenderLeftNav(onClick, disabled) {
    return (
      <LeftNav
        disabled={disabled}
        onClick={this.handleLeft() && onClick}
        full={this.state.full}
        fullcustom={this.state.fullcustom}
      />
    );
  }

  /**
   * Renderiza nuestro botón derecho de nuestra galería
   */
  myrenderRightNav(onClick, disabled) {
    return (
      <RightNav
        disabled={disabled}
        onClick={this.handleRight() && onClick}
        full={this.state.full}
        fullcustom={this.state.fullcustom}
      />
    );
  }

  /**
   * Maneja el cierre del modo fullscreen
   */
  handleClose(e, onClick) {
    this.setState({
      index: 0,
      fullcustom: false,
      full: false,
    });
  }

  /**
   * Renderiza nuestros botones de la esquina superior derecha de nuestra galería
   */
  myrenderFullscreenButton(onClick, isFullscreen) {
    return (
      <div>
        {this.state.full && !this.state.fullcustom ? (
          <p className="counter">
            {this.state.index + 1 + " / " + this.state.arr.length}
          </p>
        ) : null}
        {this.state.fullcustom ? (
          <Minimize
            onClick={(e) =>
              this.setState({ fullcustom: !this.state.fullcustom })
            }
          />
        ) : (
          <FullScreen
            onClick={(e) =>
              this.setState({ fullcustom: !this.state.fullcustom })
            }
          />
        )}

        <Times onClick={(e) => this.handleClose(e, onClick)} />
      </div>
    );
  }

  render() {
    var lbreak = 3; // Variable para nuestro salto de linea dependiendo del tipo de archivo
    const listItems = this.state.arr.map((item, position) => {
      switch (item.Tipo) {
        case image:
          // Por si la imagen previa es de distinto tipo regresa un salto de linea y la imágen
          if (lbreak !== 1) {
            lbreak = 1;
            return [
              <div className="line-break"></div>,
              <div
                onClick={(e) => this.handleFullscreen(e, position)}
                key={url + item.Archivo.url + position}
                className="vistasDiv"
              >
                <JustImage
                  path={url + item.Archivo.url}
                  caption={item.Descripcion}
                  w={500}
                  h={300}
                />
              </div>,
            ];
          }
          // En caso de ser del mismo tipo(imagen)
          return (
            <div
              onClick={(e) => this.handleFullscreen(e, position)}
              key={url + item.Archivo.url + position}
              className="vistasDiv"
            >
              <JustImage
                path={url + item.Archivo.url}
                caption={item.Descripcion}
                w={500}
                h={300}
              />
            </div>
          );
          // eslint-disable-next-line
          break;
        case video:
          // Por si la imagen previa es de distinto tipo regresa un salto de linea y la imágen
          if (lbreak !== 2) {
            lbreak = 2;
            return [
              <div className="line-break"></div>,
              <div
                onClick={(e) => this.handleFullscreen(e, position)}
                key={item.Thumbnail.url + position}
                className="vistasDiv"
              >
                <JustImage
                  path={url + item.Thumbnail.url}
                  caption={item.Descripcion}
                  w={510}
                  h={300}
                />
              </div>,
            ];
          }
          // En caso de ser del mismo tipo(video)
          return (
            <div
              onClick={(e) => this.handleFullscreen(e, position)}
              key={item.Thumbnail + position}
              className="vistasDiv"
            >
              <JustImage
                path={url + item.Thumbnail.url}
                caption={item.Descripcion}
                w={510}
                h={300}
              />
            </div>
          );
          // eslint-disable-next-line
          break;
        case v360:
          // Por si la imagen previa es de distinto tipo regresa un salto de linea y la imágen
          if (lbreak !== 3) {
            lbreak = 3;
            return [
              <div className="line-break"></div>,
              <div
                onClick={(e) => this.handleFullscreen(e, position)}
                key={url + item.Archivo.url + position}
                className="vistasDiv"
              >
                <JustImage
                  path={url + item.Archivo.url}
                  caption={item.Descripcion}
                  w={1594}
                  h={420}
                />
              </div>,
            ];
          }
          // En caso de ser del mismo tipo(vista 360)
          return (
            <div
              onClick={(e) => this.handleFullscreen(e, position)}
              key={url + item.Archivo.url + position}
              className="vistasDiv"
            >
              <JustImage
                path={url + item.Archivo.url}
                caption={item.Descripcion}
                w={1594}
                h={420}
              />
            </div>
          );
          // eslint-disable-next-line
          break;
        case r3d:
          // Por si la imagen previa es de distinto tipo regresa un salto de linea y la imágen
          if (lbreak !== 2) {
            lbreak = 2;
            return [
              <div className="line-break"></div>,
              <div
                onClick={(e) => this.handleFullscreen(e, position)}
                key={url + item.Thumbnail.url + position}
                className="vistasDiv"
              >
                <JustImage
                  path={url + item.Thumbnail.url}
                  caption={item.Descripcion}
                  w={510}
                  h={300}
                />
              </div>,
            ];
          }
          // En caso de ser del mismo tipo(recorrido)
          return (
            <div
              onClick={(e) => this.handleFullscreen(e, position)}
              key={url + item.Archivo.url + position}
              className="vistasDiv"
            >
              <JustImage
                path={url + item.Archivo.url}
                caption={item.Descripcion}
                w={510}
                h={300}
              />
            </div>
          );
          // eslint-disable-next-line
          break;
        default:
          return null;
          // eslint-disable-next-line
          break;
      }
    });
    return (
      <div className="vistas">
        {listItems}
        <div
          className="legalGaleria"
          style={{ color: localStorage.getItem("color") }}
        >
          <Legal />
        </div>
        {this.state.full && (
          <div id="fullscreen">
            <ImageGallery
              items={this.state.tmp}
              lazyLoad={true}
              onSlide={(e) => this.setState({ index: e })}
              startIndex={this.state.index}
              ref={this.fullscreen}
              showPlayButton={false}
              showThumbnails={false}
              renderFullscreenButton={this.myrenderFullscreenButton}
              renderLeftNav={this.myrenderLeftNav}
              renderRightNav={this.myrenderRightNav}
              renderItem={this._myImageGalleryRenderer.bind(this)}
              disableSwipe={true}
              useBrowserFullscreen={false}
            />
          </div>
        )}
      </div>
    );
  }
}