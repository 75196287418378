import React from "react";
import { Link, useParams } from "react-router-dom";

/**
 * Regresa el componente de la pantalla Fallback(cuando hay errores)
 * @param  {object}   params    Obtiene la información de la url
 * @return {Component}        Componente de la pantalla FALLBACK
 */
export const Fallback = () => {
  let paramas = useParams();
  return (
    <div id="fallback">
      <div>
        <h1>Página no disponible</h1>
        <Link to={"/" + paramas.desarrollo}>Volver a inicio</Link>
      </div>
    </div>
  );
};
