import React from "react";
import {Link, Redirect} from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { url,urlRestAPI } from "../queries/URL";
import { Refresh } from "./Refresh";



const liCls =
  "p-3 border text-gray-700 hover:text-white hover:bg-indigo-700 cursor-pointer";

 const onSubmit = (i) => {
  localStorage.setItem('pass', 'false')
   console.log('clic '+i)
   const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ desarrollo:  i})
};
  fetch(urlRestAPI+"/menu",requestOptions)
.then((response) => {
  console.log(response.status);
  if(response.status===200) {
    //console.log(response.json())
    var data= response.json();
    console.log(data)
    return data;
  } else {
   return false;
  }
})
.then((recurso) => {
  console.log(recurso)
if (recurso===false) {
  this.setState({
    refresh:recurso,
  });
  
}else{
  
  console.log(recurso.desarrollos[0].identidad.Logo.url);
    var data= recurso;
  
  
  if (recurso.desarrollos[0].SplashScreen===null) {
    localStorage.setItem('url', 'nada')
  }else{
    localStorage.setItem('url', recurso.desarrollos[0].SplashScreen.url)
  }

 
}

}).catch(function(error) {
  return <Refresh />
});
setTimeout(() => {
   window.location.href = "/"+i;

}, 1000);
 } 
 const sampleData = [];   

const Selector = ({ data = [],data2=[], setOpen,llave }) => (

  <div key={llave} className="lista">
    <ul key={llave+"ul"} className="ulDesarrollo">
      {data.map((item, i) => (
      <Link key={item+i} className="referencia" to={{ pathname: "/"+data2[i] }}  >
      <li key={"torre"+sampleData[i]+item} className="listaDesarrollo" onClick={() =>{ setOpen(false);onSubmit(data2[i]);}}>
          <p className="itemText">{item}</p>
         
        </li>
</Link>
      ))}
    </ul>
  </div>
);

export default Selector;
