import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MapContainer as LeafletMap, GeoJSON, SVGOverlay, TileLayer } from "react-leaflet";
import L, { CRS } from "leaflet";
import { getDepartamento,getModelos } from "../queries/Queries.js";
/**
 * Regresa el mapeado del GiS
 * @param  {Object}   params          Obtiene las variables de la url
 * @param  {Object}   data            Información del geojson para el trazado
 * @param  {Object}   disponibilidad  Información de la disponibilidad
 * @return {Component}  Mapa del GIS
 */
export const GeoJsonMapBack = ({ data, disponibilidadData, zoomControl ,datos,idDesarrollo}) => {
  //console.log(zoomControl);
  var modelo = localStorage.getItem("prototipoID");
  var filtradoType = localStorage.getItem("filtradoType");
  const params = useParams();
  const history = useHistory();
  const center = [ 25.5570175925793,-100.266724794347583]

  // console.log('data',data);
  const jsonData = JSON.parse(data.toString())

  const mapBounds = L.geoJson(jsonData, {}).getBounds();

  var lat = mapBounds["_northEast"].lat - mapBounds["_southWest"].lat;
  var long = mapBounds["_northEast"].lng - mapBounds["_southWest"].lng;

  var [arr, setArr] = useState([]);

  // Establece los bounds del mapa
  useEffect(() => {
    var l = L.geoJSON(jsonData);

    l.eachLayer((lay) => {
      try {
        
        if (lay.getBounds().getCenter() !== undefined) {
          var aux = [
            [
              lay.getBounds()["_southWest"]["lat"],
              lay.getBounds()["_southWest"]["lng"],
            ],
            [
              lay.getBounds()["_northEast"]["lat"],
              lay.getBounds()["_northEast"]["lng"],
            ],
            lay.feature.properties.Text,
          ];
          setArr((arr) => [...arr, aux]);
        }
      } catch (error) {
        
      }
    });
  }, [data]);

  

  /**
   * Regresa la forma de coloreado dependiendo de la disponibilidad
   * @param  {String}   status    Status del "departamento"
   * @return {Component}  Objeto con propiedades para coloreado
   */
  function Status(status) {
    switch (status) {
      case "D":
        return {
          color: "#637465",
          weight: 0.5,
          fillColor: "#637465",
          fillOpacity: 1,
        };
      case "S":
        return {
          color: "#C5AF60",
          weight: 0.5,
          fillColor: "#C5AF60",
          fillOpacity: 1,
        };
      case "V":
        return {
          color: "#A76753",
          weight: 0.5,
          fillColor: "#A76753",
          fillOpacity: 1,
        };
      case "ND":
        return {
          color: "#D2CBC6",
          weight: 0.5,
          fillColor: "#D2CBC6",
          fillOpacity: 1,
        };
        case "AV":
        return {
          color: "#60992D",
          weight: 0.5,
          fillColor: "#60992D",
          fillOpacity: 1,
        };
      default:
        return {
          color: "#000",
          weight: 0.5,
          fillColor: "#000",
          fillOpacity: 1,
        };
    }
  }

 
  /**
   * Imprime los textos de cada departamento en pantalla
   */
  const listItems = arr.map((item,i) => {
    return (
      <SVGOverlay attributes={{ stroke: "red" }} key={'lote'+i} bounds={item.slice(0, 2)}>
        <text
          x="50%"
          y="60%"
          stroke="transparent"
          fill="white"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontFamily="Montserrat"
          fontSize="15.25px"
          
        >
          {item.slice(2, 3)}
        </text>
      </SVGOverlay>
    );
  });

  try {
    return (
      
      <LeafletMap
      center={[long / 2, lat / 2]}
      scrollWheelZoom={false}
      zoomDelta={0.25}
      zoomSnap={0}
      bounds={mapBounds}
      >
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            // url='https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
            // url='https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
            // url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            // url=''
            attribution={false}
            // maxNativeZoom={19}
            maxZoom={25}
            minZoom={4}
            updateWhenZooming={true}
            updateWhenIdle={true}
        />
        {console.log(disponibilidadData)}
         { console.log(jsonData)}
        <GeoJSON
          data={jsonData}
          style={(feature) => {
            try {
              // Filtra por prototipo y luego busca el departamento para colorear
              // eslint-disable-next-line
              
              if(modelo === undefined || modelo === null || filtradoType === "Ninguno"){ // Caso para CEP cuando no se selecciona ningún prototipo se pinta todo.
                if(feature.properties.lote_id === "ND"){
                  return Status("ND");
                }
                if(feature.properties.lote_id === "AV"){
                  return Status("AV");
                }
                if (feature.properties.id_prototipo === localStorage.getItem("prototipoID")&&localStorage.getItem("disponibilidad")==="true"&&localStorage.getItem("flag")==="false") {
                  
                const disp = disponibilidadData.filter(
                    (item) => item.lote_id === feature.properties.lote_id
                );
                return Status(disp[0].status);
                }
                else if(localStorage.getItem("disponibilidad")==="false"){
                  
                
                  const disp = disponibilidadData.filter(
                      (item) => item.lote_id === feature.properties.lote_id
                  );
                  return Status(disp[0].status);
                 
                  
                }
                else if(localStorage.getItem("disponibilidad")==="true"&&localStorage.getItem("flag")==="true"){
                  
                 // console.log("val1 " + feature.properties.lote_id);
                  //
                  const disp = disponibilidadData.filter(
                      (item) => item.lote_id === feature.properties.lote_id
                  );
                  if (disp.length!==0) {
                    if (disp[0].status!==undefined) {
                      return Status(disp[0].status);
                    }else{
                      return {
                        color: "#EAE6E4",
                        weight: 0.5,
                        fillColor: "#EAE6E4",
                        fillOpacity: 1,
                        pointerEvents: "none !important",
                      };
                    }
                  }
                  //return Status(disp[0].status);
                  
                }
                
              }
              if (feature.properties.id_prototipo == modelo) {
                
                
                const disp = disponibilidadData.filter(
                  (item) => item.lote_id === feature.properties.lote_id
                );
                if (disp.length!==0) {
                  return Status(disp[0].status);
                }else{
                  return {
                    color: "#EAE6E4",
                    weight: 0.5,
                    fillColor: "#EAE6E4",
                    fillOpacity: 1,
                    pointerEvents: "none !important",
                  };
                }
                //return Status(disp[0].status);
              } else {
                // Caso donde no pertenece a el prototipo actual
                return {
                  color: "#EAE6E4",
                  weight: 0.5,
                  fillColor: "#EAE6E4",
                  fillOpacity: 1,
                  pointerEvents: "none !important",
                };
              }
            } catch (error) {
              console.error(error);
              return {
                color: "#EAE6E4",
                weight: 0.5,
                fillColor: "#EAE6E4",
                fillOpacity: 1,
                pointerEvents: "none !important",
              };
            }
          }}
          onEachFeature={(feature, layer) => {
            // Se añade las acciones cuando se da click a algun "departamento"
            layer.on("click", (e) => {
              // Por si el usuario da click en un "departamento" que no es del prototipo
             console.log(feature.properties)
              localStorage.setItem(
              "loteID",
              feature.properties.lote_id
               // "M64L02"
          );
              if(feature.properties.lote_id !== "ND"){
                localStorage.setItem(
                    "prototipoID",
                    feature.properties.id_prototipo
                );
                if (datos!==undefined) {
                  localStorage.setItem("torreName",datos.Torres[0].Nombre)
                //  localStorage.setItem("torreID",)
                 
                  var data2 = getModelos(
                    idDesarrollo
                  );
                
                  data2.then((res) => {
                    var datos=res.data.data
                    for (let index = 0; index < datos.desarrollos[0].Torres.length; index++) {
                    
                      if (localStorage.getItem("torreName")===datos.desarrollos[0].Torres[index].Nombre) {
                        var id =datos.desarrollos[0].Torres[index].id;
                      localStorage.setItem("torreID",id)
                    }
                    }
                    
                   
                
                  })

                  
                }
                
                history.push(
                    "/" +
                    params.desarrollo +
                    "/" +
                    localStorage
                        .getItem("torreName")
                        .split(" ")
                        .join("")
                        .toLocaleLowerCase() +
                    "/departamento/" +
                    feature.properties.Text
                );
              }
            });
          }}
        />
        {listItems}
      </LeafletMap>
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};
