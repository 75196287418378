import React from "react";
import { Link, useParams } from "react-router-dom";

/**
 * Regresa el componente de la pantalla Fallback(cuando hay errores)
 * @param  {object}   params    Obtiene la información de la url
 * @return {Component}        Componente de la pantalla FALLBACK
 */
export const Refresh = () => {
  let paramas = useParams();

  const handledConsulta = ()=>{
    localStorage.setItem("flag","false")
    window.history.back()
  }
  
  var URLactual = document.referrer;
  console.log(URLactual.indexOf(window.location.host));
  return (
    <div id="fallback">
      <div>
        <h1>Ocurrió un error al obtener la información</h1>
        <Link
                    to={{
                     // pathname:
                        //URLactual
                      /*state: {
                        modelo: "Modelo " + modelo + 1,
                        returnTo: link.location.pathname,
                      },*/
                    }}
                    
                   
                  >
                  <button  className="botonConsulta" onClick={handledConsulta}>
   <h1 className="palabraConsulta">Volver a la sección anterior</h1>

  </button>

                  </Link>
      </div>
    </div>
  );
};
