import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";

/**
 * Regresa el componente de la pantalla Fallback(cuando hay errores)
 * @param  {object}   params    Obtiene la información de la url
 * @return {Component}        Componente de la pantalla FALLBACK
 */
export const SplashScreen = () => {
    let color = localStorage.getItem('colorMenu');
    let url = localStorage.getItem('url');
    let screen = false;
    let n=0;
    if (url===null) {
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
      
    }
    
  return (
    <div>
    <div style={{backgroundColor:color,height:'1080px'}}>
      <div>
      
      <img src={'https://pantallas.carza.com/'+url}  className={'splash'} alt="logo" />
       </div>

    </div>
</div>
  );
};
