import React from "react";
import { Drawer } from "../components/Drawer.js";
import { Title } from "../components/Title";
import { GaleriaCustom } from "../components/GaleriaCustom";
import { Fallback } from "../components/fallback";
import { getAmenidades,getBusinessIdentity } from "../queries/Queries.js";
import "../stylesheets/dist/main.css";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Loading } from "../components/Loading.js";
import { url,urlRestAPI } from "../queries/URL";
import { Refresh } from "../components/Refresh.js";
import { Inicio } from "./Inicio.js";
/**
 * Regresa el componente de la pantalla AMENIDADES
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Number}   torre   Torre seleccionada
 * @param  {Number}   tab     Tab seleccionadada
 * @return {Component}        Componente de la pantalla AMENIDADES
 */
 var n=0;
 var x; 
export class Amenidades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      torre: 0,
      tab: 0,
      refresh:undefined
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.handleTorre = this.handleTorre.bind(this);
    this.method = new Inicio();
  }

  /**
   * Hace la petición y la guarda la respuesta en data
   */
  componentDidMount() {
    //var data = getAmenidades(this.props.match.params.desarrollo);
    //var data2 = getBusinessIdentity(this.props.match.params.desarrollo);
   /* if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
      n=0;
      x = setInterval(function(){
        console.log(n);
        
        n++;
      },1000);
    }*/
   
    const requestOptions = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/amenidades",requestOptions)
      .then((response) => {
        console.log(response.status);
    if(response.status===200) {
      //console.log(response.json())
      var data= response.json();
      console.log(data)
      return data;
    } else {
     return false;
    }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState(
        {
          data: data,
        },
        // Busca la primer tab que tenga elementos
        () => this.handleTorre(this.state.torre)
      );
      
       

        
      
      }
      
      });
   

    const requestOptions2 = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/menu",requestOptions2)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data2: data.desarrollos[0],
      });
    }
      });
    /*data2.then((res) => {
      //console.log(res.data.data.desarrollos[0].identidad.Background.url)
      this.setState({
        data2: res.data.data.desarrollos[0],
      });
    });*/
  }

  /**
   * Realiza la búsqueda de las tabs para elegir la primera que tiene elementos
   * @param  {object}   index    Número de la torre dónde se va a a buscar la primera tab que tiene elementos
   * @return {Number}            Regresa el índice de la primera tab con elementos
   */
  handleTorre(index) {
    try {
      for (
        let i = 0;
        i < this.state.data.desarrollos[0].Torres[index].amenidade.Tabs.length;
        i++
      ) {
        const element = this.state.data.desarrollos[0].Torres[index].amenidade
          .Tabs[i];
        if (element.Media.length > 0) {
          console.log(i);
          return i;
        }
      }
    } catch (error) {
      return 0;
    }
  }

  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
   /* console.log('stopped')
    if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    clearInterval(x); 
    var n=0;
    setTimeout(() => {
      x = setInterval(function(){
        console.log(n);
        if (n==30) {
          location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    }, 1000);
  }

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }*/
  this.method.changeScreen();
  
  }

  botonHomologado(data){
    var yes=[];
    for (let index = 0; index < data.length; index++) {
      console.log(data[index].BotonHomologadoMultimedia)
      if (data[index].BotonHomologadoMultimedia==true) {
        yes.push(data[index]);
      }
    }
    if (yes.length>0) {
      return yes;
    }else{
      return data;
    }
    
  }
  render() {
    let datos = this.state.data;
    let refresh =this.state.refresh;
    let homologado;
    console.log(refresh);
    if (refresh == false) {
      return <Refresh />
    }
    if(datos === undefined){
        return <Loading />
    }
    try {
      var data = this.state.data.desarrollos[0].Torres;
      data=this.botonHomologado(data)
      return (
        <div onClick={this.changeScreen} className="multimedia">
          <Drawer data={this.state.data2}/>
          <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />
          <Title title={"Amenidades"} />
          <ul id="verticalTab">
            {/* En caso de ser solo una torre no despliega el menú de torres */}
            
            {data.length > 1 &&
            
              data.map((item, index) => {
                // En caso de que la torre no tenga amenidades
                if (item.amenidade === null) {
                  return null;
                }
                // Muestra la torre
                return (
                  <li
                    key={item.Nombre}
                    onClick={(e) =>
                      this.setState(
                        {
                          torre: index,
                          tab: this.handleTorre(index),
                        },
                        () => {
                          console.log(
                            "torre: " + this.state.torre,
                            "tab: " + this.state.tab
                          );
                        }
                      )
                    }
                    className={this.state.torre === index ? "sverticaltab" : ""}
                    style={{
                      color:
                        this.state.torre === index
                          ? localStorage.getItem("color")
                          : "",
                    }}
                  >
                    {item.Nombre}
                  </li>
                );
              })}
          </ul>
          <div className={"navigation " + (data.length > 1 ? "hastabs" : "")}>
            <ul>
              {/* Iteración sobre las tabs disponibles de cada torre  */}
              {data[this.state.torre].amenidade.Tabs.map((item, index) => {
                try {
                  // Si tiene elementos muestra la tab
                  if (item.Media.length > 0) {
                    return (
                      <li
                        key={index}
                        onClick={(e) => this.setState({ tab: index })}
                        className={
                          this.state.tab === index ? "selected-nav" : ""
                        }
                        style={{
                          color:
                            this.state.tab === index
                              ? localStorage.getItem("color")
                              : "",
                          borderBottom:
                            this.state.tab === index
                              ? "2px solid " + localStorage.getItem("color")
                              : "",
                        }}
                      >
                        {item.Tab}
                      </li>
                    );
                  }
                  return null;
                } catch (error) {
                  return null;
                }
              })}
            </ul>
            {/* Renderiza la tab correspondiente */}
            {data[this.state.torre].amenidade.Tabs[this.state.tab] && (
              <GaleriaCustom
                data={
                  data[this.state.torre].amenidade.Tabs[this.state.tab].Media
                }
                key={this.state.torre + " " + this.state.tab}
              />
            )}
          </div>
        </div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}
