import React from "react";
import { Drawer } from "../components/Drawer";
import { Title } from "../components/Title";
import { TabImage } from "../components/TabImage";
import { Legal } from "../components/Legal";
import { Fallback } from "../components/fallback";
import { getMasterplan,getBusinessIdentity } from "../queries/Queries.js";
import "../stylesheets/dist/main.css";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Loading } from "../components/Loading.js";
import { url,urlRestAPI } from "../queries/URL";
import { Refresh } from "../components/Refresh";
import { Inicio } from "./Inicio";
/**
 * Regresa el componente de la pantalla MASTERPLAN
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Array}    images  Guarda la imagen actual dependiendo de la tab
 * @param  {Number}   tab     Guarda el index del masterplan seleccionado
 * @return {Component}        Componente de la pantalla MASTERPLAN
 */
 var n=0;
 var x; 
export class Masterplan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      images: [],
      tab: 0,
      refresh:undefined,
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.method = new Inicio();
  }

  /**
   * Hace la petición y guarda la respuesta en data
   * Restablece los valores por defecto de la pantalla MODELOS
   */
  componentDidMount() {
    localStorage.setItem("torre", 0);
    localStorage.setItem("prototipo", 0);
    localStorage.setItem("tab", 0);
    //var data2 = getBusinessIdentity(this.props.match.params.desarrollo);

   
    const requestOptions = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/masterplan",requestOptions)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }    
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data: data,
      });
    }
      });

    //var data = getMasterplan(this.props.match.params.desarrollo);
   /* data.then((res) => {
      this.setState({
        data: res.data.data,
      });
    });
*/
    const requestOptions2 = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/menu",requestOptions2)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }  
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data2: data.desarrollos[0],
      });
    }
      });
   /* data2.then((res) => {
      //console.log(res.data.data.desarrollos[0].identidad.Background.url)
      this.setState({
        data2: res.data.data.desarrollos[0],
      });
    });*/
  }

  /*invocation(initial) {
    //alert('invoked')
    clearInterval( initial )
    console.log('clic fuera');
    if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
      
     
   console.log('clic dentro');
   clearInterval( initial )
   initial=setInterval(() => {
    location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
  }, 10000)
  //hacer un timer con la hora actual y la hora +10 minutos
  //this.setState({initial:initial})
  console.log(initial)
  }
  console.log(initial)
  this.setState({initial:initial})
  
  }*/
  
   
  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
   /* console.log('stopped')
    if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    clearInterval(x); 
    var n=0;
    setTimeout(() => {
      x = setInterval(function(){
        console.log(n);
        if (n==30) {
          location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    }, 1000);
  }

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }*/
  this.method.changeScreen();
  
  }

  render() {
    let datos = this.state.data;
    let refresh =this.state.refresh;
    console.log(refresh);
    if (refresh == false) {
      return <Refresh />
    }
    if(datos === undefined){
        return <Loading />
    }
    try {
      var data = this.state.data.desarrollos[0].Torres;
      return (
        <div onClick={this.changeScreen} className="masterplan">
          <Drawer data={this.state.data2} />
          <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />
          <Title
            title={"Masterplan"}
            text={data[this.state.tab].masterplan.Descripcion}
          />
          <div id="tabs">
            <ul>
              {data.length > 1 &&
                data.map((item, index) => {
                  // En caso de no haber masterplan ignora la tab.
                  if (item.masterplan === null) {
                    return null;
                  }
                  return (
                    <li
                      key={index}
                      onClick={(e) => this.setState({ tab: index })}
                      className={index === this.state.tab ? "selected-nav" : ""}
                      style={{
                        color:
                          this.state.tab === index
                            ? localStorage.getItem("color")
                            : "",
                        borderBottom:
                          this.state.tab === index
                            ? "2px solid " + localStorage.getItem("color")
                            : "",
                      }}
                    >
                      {item.Nombre}
                    </li>
                  );
                })}
            </ul>
            <Image data={data} index={this.state.tab} />
            <div
              className="legalMaster"
              style={{ color: localStorage.getItem("color") }}
            >
              <Legal />
            </div>
          </div>
        </div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}

/**
 * Muestra la imagen de la tab seleccionada
 * @param  {object}   data    Información de todos los masterplan
 * @param  {Number}   tab     Masterplan seleccionado
 * @return {Component}        Imagen del masterplan
 */
export const Image = ({ data, index }) => {
  var path = data[index]["masterplan"];
  console.log(path, index);
  try {
    var images = [
      {
        original: url + path.Imagen.url,
        thumbnail: url + path.Imagen.url,
        description: path.Imagen.caption,
      },
    ];
    return <TabImage images={images} />;
  } catch (error) {
    return null;
  }
};
