import React, { createRef } from "react";
import {
  LeftNav,
  RightNav,
  Minimize,
  FullScreen,
  Times,
} from "../components/Icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ImageGallery from "react-image-gallery";

/**
 * Regresa el Slider de la pantalla CONCEPTO
 * @param  {Number}   index   Indice de la imagen en la cual se encuentra la Galería
 * @param  {Boolean}  full    Estado de la pantalla fullscreen(no total)
 * @param  {Boolean}  fullcustom    Estado de la pantalla fullscreen(total-completo)
 * @return {Component}        Regresa un Slider
 */
export class TabImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      full: false,
      fullcustom: false,
    };

    this.myrenderFullscreenButton = this.myrenderFullscreenButton.bind(this);
    this.myrenderLeftNav = this.myrenderLeftNav.bind(this);
    this.myrenderRightNav = this.myrenderRightNav.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    this._myImageGalleryRenderer = this._myImageGalleryRenderer.bind(this);

    this.fullscreen = createRef();
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    // Detecta que no esté activos los botones
    document.getElementById("times").style.display = "none";
    document.getElementById("min").style.display = "none";

    // En caso de hacer click en las imagenes
    document.body.addEventListener("click", (event) => {
      if (
        event.target.tagName === "IMG" &&
        event.target.className === "justimage"
      ) {
        this.handleFullscreen();
      }
    });

    // En caso de presionar tecla ESC y salir del modo fullscreen
    window.addEventListener("keydown", (event) => {
      try {
        // eslint-disable-next-line
        if (event.key == "Escape") {
          this.handleClose();
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  /**
   * Maneja los errores que provienen de los archivos del arreglo tmp
   */
  _handleImageError(event) {
    if (
      this.props.defaultImage &&
      event.target.src.indexOf(this.props.defaultImage) === -1
    ) {
      event.target.src = this.props.defaultImage;
    }
  }

  /**
   * Renderiza la galería dependiendo el tipo del archivo y en que modo de fullscreen se encuentra
   */
  _myImageGalleryRenderer(item) {
    // your own image error handling
    const onImageError = this._handleImageError;

    // Cambia el color de fondo dependiendo el modo de fullscreen en el que estes
    if (this.state.fullcustom) {
      var element = document.getElementsByClassName("image-gallery-slides")[0];
      element.id = "full";
      document.getElementById("full").style.background = "transparent";
    } else {
      try {
        document.getElementById("full").style.background = "transparent";
      } catch (error) {}
    }

    // Modo fullscren ( no total)
    if (this.state.full && !this.state.fullcustom) {
      return (
        <div style={{ position: `relative`, margin: `15vh 10vw` }}>
          <TransformWrapper>
            <TransformComponent>
              <img
                src={item.original}
                alt={item.originalAlt}
                srcSet={item.srcSet}
                sizes={item.sizes}
                onLoad={this.props.onImageLoad}
                onError={onImageError.bind(this)}
                className="ima"
                style={{ width: `80vw`, height: `70vh` }}
              />
            </TransformComponent>
          </TransformWrapper>

          {item.description && !this.state.fullcustom && (
            <span className="description">
              {item.description}
              {item.link && item.linkLabel && (
                <a className="my-own-class" href={item.link}>
                  {item.linkLabel}
                </a>
              )}
            </span>
          )}
        </div>
      );
    }
    // Modo fullscreen total
    if (this.state.fullcustom) {
      return (
        <div
          style={{
            position: `relative`,
            margin: `40px auto`,
            display: `flex`,
            justifyContent: "center",
          }}
        >
          <TransformWrapper>
            <TransformComponent>
              <img
                src={item.original}
                alt={item.originalAlt}
                srcSet={item.srcSet}
                sizes={item.sizes}
                onLoad={this.props.onImageLoad}
                onError={onImageError.bind(this)}
                // className='image-gallery-image'
                style={{ width: `auto`, height: `1000px` }}
              />
            </TransformComponent>
          </TransformWrapper>

          {!this.state.fullcustom && item.description && (
            <span className="description">
              {item.description}
              {item.link && item.linkLabel && (
                <a className="my-own-class" href={item.link}>
                  {item.linkLabel}
                </a>
              )}
            </span>
          )}
        </div>
      );
    } else {
      // Caso por defecto
      return (
        <div>
          <img
            src={item.original}
            alt={item.originalAlt}
            srcSet={item.srcSet}
            sizes={item.sizes}
            onLoad={this.props.onImageLoad}
            onError={onImageError.bind(this)}
            className="justimage"
            // className={this.state.full ? "image-gallery-image" : "justimage"}
          />

          {this.state.full && item.description && (
            <span className="description">
              {item.description}
              {item.link && item.linkLabel && (
                <a className="my-own-class" href={item.link}>
                  {item.linkLabel}
                </a>
              )}
            </span>
          )}
        </div>
      );
    }
  }

  /**
   * Maneja la entrada al modo fullscreen(no total)
   */
  handleFullscreen() {
    try {
      document.getElementById("times").style.display = "block";
      document.getElementById("min").style.display = "block";
      this.fullscreen.current.fullScreen();
      this.setState({
        full: true,
      });
    } catch (error) {}
  }

  /**
   * Renderiza nuestro botón izquierdo de nuestra galería
   */
  myrenderLeftNav(onClick, disabled) {
    return (
      <LeftNav
        disabled={disabled}
        onClick={onClick}
        full={this.state.full}
        fullcustom={this.state.fullcustom}
      />
    );
  }

  /**
   * Renderiza nuestro botón derecho de nuestra galería
   */
  myrenderRightNav(onClick, disabled) {
    return (
      <RightNav
        disabled={disabled}
        onClick={onClick}
        full={this.state.full}
        fullcustom={this.state.fullcustom}
      />
    );
  }

  /**
   * Maneja el cierre del modo fullscreen
   */
  handleClose(e, onClick) {
    this.fullscreen.current.exitFullScreen();
    document.getElementById("times").style.display = "none";
    document.getElementById("min").style.display = "none";
    this.setState({
      full: false,
      fullcustom: false,
      index: 0,
    });
  }

  /**
   * Renderiza nuestros botones de la esquina superior derecha de nuestra galería
   */
  myrenderFullscreenButton(onClick, isFullscreen) {
    return (
      <div>
        {this.state.full && !this.state.fullcustom ? (
          <p className="counter">
            {this.state.index + 1 + " / " + this.props.images.length}
          </p>
        ) : null}
        {this.state.fullcustom ? (
          <Minimize
            onClick={(e) =>
              this.setState({ fullcustom: !this.state.fullcustom })
            }
          />
        ) : (
          <FullScreen
            onClick={(e) =>
              this.setState({ fullcustom: !this.state.fullcustom })
            }
          />
        )}

        <Times onClick={(e) => this.handleClose(e, onClick)} />
      </div>
    );
  }

  render() {
    return (
      <div>
        <ImageGallery
          items={this.props.images}
          startIndex={this.state.index}
          ref={this.fullscreen}
          showPlayButton={false}
          showThumbnails={false}
          renderFullscreenButton={this.myrenderFullscreenButton}
          renderLeftNav={this.myrenderLeftNav}
          renderRightNav={this.myrenderRightNav}
          renderItem={this._myImageGalleryRenderer.bind(this)}
          useBrowserFullscreen={false}
        />
      </div>
    );
  }
}
