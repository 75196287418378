import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { urlRestAPI } from './queries/URL';
import reportWebVitals from './reportWebVitals';
import {Helmet} from "react-helmet";



//localStorage.setItem('url',null)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ desarrollo:  window.location.pathname.substr(1,1)})
  };
    fetch(urlRestAPI+"/menu",requestOptions)
  .then((response) => {
    console.log(response.status);
    if(response.status===200) {
      //console.log(response.json())
      var data= response.json();
      console.log(data)
      return data;
    } else {
     return false;
    }
  })
  .then((recurso) => {
    console.log(recurso)
  if (recurso===false) {
    console.log(recurso)
    
  }else{
    
    
      var data= recurso;
      console.log(data.desarrollos[0].identidad.Font.name);
      if (recurso.desarrollos[0].SplashScreen===null) {
        localStorage.setItem('url', 'nada')
      }else{
        localStorage.setItem('url', recurso.desarrollos[0].SplashScreen.url)
      }
      localStorage.setItem('font',data.desarrollos[0].identidad.Font.name)
   
    localStorage.setItem('background',data.desarrollos[0].identidad.Background.url)
    localStorage.setItem('logo',data.desarrollos[0].identidad.Logo.url)
    localStorage.setItem('logoMenu',data.desarrollos[0].identidad.LogoMenu.url)

    localStorage.setItem(
      "color",
     data.desarrollos[0].identidad.ColorPrimario
    );

    
    
  
    localStorage.setItem(
      "colorMenu",
      data.desarrollos[0].identidad.ColorMenu
    );

    localStorage.setItem(
      "tabs",
      JSON.stringify(data.desarrollos[0])
    );
  
    localStorage.setItem(
      "colorTerciario",
      data.desarrollos[0].identidad.ColorTerciario
    );
   

    var name = data.desarrollos[0].identidad.Font.name.split(".")[0];
  console.log(name)
    var notoSansRegular = new FontFace(
      name,
      "url(" + data.desarrollos[0].identidad.Font.url + " )",
      {
        style: "normal",
        weight: "400",
        display: "block",
      }
    );
    document.fonts.add(notoSansRegular);
    notoSansRegular.load();
    
   
    
  }
  }).catch(function(error) {
    return null;
  });
    
  

 

console.log(window.location.pathname.substr(1,1))
ReactDOM.render(
  /*React.StrictMode>
    <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximumscale=1.0, user-scalable=no" />
               
  </Helmet>*/
//busqueda(),

    <App />,
  /*</React.StrictMode>,*/
  document.getElementById('root')
);


/*client
  .query({
    query: gql`
      query GetDesarrollo {
        desarrollos{
          id
          Nombre
            }
      }
    `
  })
  .then(result => console.log(result));*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
