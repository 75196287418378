import React from "react";
import { Link } from "react-router-dom";

/**
 * Regresa el componente Breadcrumb de las pantallas Disponibilidad y Departamento
 * @return {Component}        Componente Breadcrumb
 */
export class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);
    this.nextPath = this.nextPath.bind(this);
  }

  //Navega a el path recibido
  nextPath(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <div id="breadcrumb">
        {/* Regreso a la pantalla anterior con estado para saber de que lado viene la animación */}
        {this.props.lk ? (
          <Link
            to={{
              pathname: this.props.lk,
              state: {
                returnTo: this.props.links[this.props.links.length - 1],
              },
            }}
            onClick={this.props.onClick}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M0.549938 22.6728C0.550499 22.6722 0.550968 22.6716 0.551624 22.671L10.3489 12.921C11.0828 12.1906 12.27 12.1933 13.0006 12.9274C13.7311 13.6614 13.7283 14.8485 12.9943 15.579L6.41644 22.125H46.125C47.1606 22.125 48 22.9645 48 24C48 25.0356 47.1606 25.875 46.125 25.875H6.41653L12.9942 32.421C13.7282 33.1515 13.731 34.3387 13.0005 35.0726C12.2699 35.8068 11.0827 35.8093 10.3488 35.079L0.551529 25.329C0.550968 25.3284 0.550499 25.3278 0.549843 25.3272C-0.184502 24.5943 -0.182156 23.4033 0.549938 22.6728Z"
                  fill={localStorage.getItem("color")}
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="48"
                    height="48"
                    fill="red"
                    transform="matrix(-1 0 0 1 48 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </Link>
        ) : (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M0.549938 22.6728C0.550499 22.6722 0.550968 22.6716 0.551624 22.671L10.3489 12.921C11.0828 12.1906 12.27 12.1933 13.0006 12.9274C13.7311 13.6614 13.7283 14.8485 12.9943 15.579L6.41644 22.125H46.125C47.1606 22.125 48 22.9645 48 24C48 25.0356 47.1606 25.875 46.125 25.875H6.41653L12.9942 32.421C13.7282 33.1515 13.731 34.3387 13.0005 35.0726C12.2699 35.8068 11.0827 35.8093 10.3488 35.079L0.551529 25.329C0.550968 25.3284 0.550499 25.3278 0.549843 25.3272C-0.184502 24.5943 -0.182156 23.4033 0.549938 22.6728Z"
                fill={localStorage.getItem("color")}
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="48"
                  height="48"
                  fill="red"
                  transform="matrix(-1 0 0 1 48 0)"
                />
              </clipPath>
            </defs>
          </svg>
        )}
        {/* Links para cada uno de los links recibidos  */}
        {this.props.tabs
          .slice(0, this.props.tabs.length - 1)
          .map((tab, index) => {
            return (
              <Link
                key={tab}
                to={{
                  pathname: this.props.links[index],
                  state: {
                    returnTo: this.props.links[this.props.links.length - 1],
                  },
                }}
              >
                {" " + tab} {index < this.props.tabs.length - 1 ? " / " : " "}{" "}
              </Link>
            );
          })}
        <a>{" " + this.props.tabs[this.props.tabs.length - 1]}</a>
      </div>
    );
  }
}
