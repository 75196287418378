import React from "react";
import "../stylesheets/dist/main.css";

/**
 * Regresa el rectangulo y el titulo que aparecen el la parte derecha de la pantalla
 * @param  {String}   title    Titulo de la panta
 * @return {Component}  Titulo de la pantalla en el lado derecho
 */
export const RightTitle = ({ title }) => {
  return (
    <div className="righttitle">
      <svg
        width="86"
        height="5"
        viewBox="0 0 86 5"
        xmlns="http://www.w3.org/2000/svg"
        fill={localStorage.getItem("color")}
      >
        <rect width="86" height="5" />
      </svg>
      <h3 className="tituloVertical">{title}</h3>
    </div>
  );
};
