import React, {Suspense} from "react";
import { Drawer } from "../components/Drawer.js";
import { Legal } from "../components/Legal.js";
import { Fallback } from "../components/fallback.js";
import { Refresh } from "../components/Refresh.js";
import { url,urlRestAPI } from "../queries/URL";
import { getBusinessIdentity } from "../queries/Queries.js";
import "../stylesheets/dist/inicio.css";
import { DrawerLeft } from "../components/DrawerLeft.js";
import ButtonWithDropDown from "../components/buttonDropDown";
import { getDesarrollo } from "../queries/Queries";
import { Loading } from "../components/Loading.js";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import { SplashScreen } from "../components/splashscreen.js";
import AnimacionLottie from "../components/AnimacionLottie.js";
/**
 * Regresa la pantalla de la página de Inicio
 * @param  {object} data Guarda la información recibida por la petición
 * @return {Component}   Background y logo central
 */
/*var initial = initial = setTimeout(() => {
  location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
}, 5000);*/
var n=0;
var x; 
export class Inicio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      refresh: undefined,
      msg:undefined
    };
    
    
    
    
  }

  /**
   * Hace la petición y guarda la respuesta en data
   * Restablece los valores por defecto de la pantalla MODELOS
   */
  componentDidMount() {

    var id =20;
    localStorage.setItem("torre", 0);
    localStorage.setItem("prototipo", 0);
    localStorage.setItem("tab", 0);
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('default')
    localStorage.setItem("idDesarrollo",this.props.match.params.desarrollo)
    if (token!=null) {
      console.log(this.props.match.params.desarrollo);
    localStorage.setItem("idDefault", token);
    }
    console.log(this.props.match.params.desarrollo);
    if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
      if (localStorage.getItem("dispo")!=="true") {
        
      
      n=0;
      x = setInterval(function(){
        console.log(n);
        
        n++;
      },1000);
    }
    //localStorage.setItem("dispo","false")
    localStorage.setItem("dispo","true")
  }
   
     // render for 5 seconds and then push to home 
  
    //123
    //var data2 = getDesarrollo(id);
    //var data2 = localStorage.getItem("selector");
    //console.log(data2);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
  };
    fetch(urlRestAPI+"/menu",requestOptions)
  .then((response) => {
    console.log(response.status);
    if(response.status===200) {
      //console.log(response.json())
      var data= response.json();
      console.log(data)
      return data;
    } else {
     return false;
    }
  })
  .then((recurso) => {
    console.log(recurso)
  if (recurso===false) {
    this.setState({
      refresh:recurso,
    });
    
  }else{
    
    console.log(recurso.desarrollos[0].identidad.Logo.url);
      var data= recurso;
    
    localStorage.setItem(
      "color",
     data.desarrollos[0].identidad.ColorPrimario
    );
    
    localStorage.setItem('url', recurso.desarrollos[0].identidad.Logo.url)
    localStorage.setItem(
      "colorMenu",
      data.desarrollos[0].identidad.ColorMenu
    );
  
    localStorage.setItem(
      "colorTerciario",
      data.desarrollos[0].identidad.ColorTerciario
    );
  
   
  }
  
  }).catch(function(error) {
    return <Refresh />
  });

  }
   
  
  componentDidMount() {
    var id =20;
    localStorage.setItem("torre", 0);
    localStorage.setItem("prototipo", 0);
    localStorage.setItem("tab", 0);
    //var data2 = getDesarrollo(id);
    //var data2 = localStorage.getItem("selector");
    //console.log(data2);
   
      
    
    setTimeout(() => {
      if (localStorage.getItem('url')==='nada') {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
    };
      fetch(urlRestAPI+"/menu",requestOptions)
    .then((response) => {
      console.log(response.status);
      if(response.status===200) {
        //console.log(response.json())
        var data= response.json();
        console.log(data)
        return data;
      } else {
       return false;
      }
    })
    .then((recurso) => {
      console.log(recurso)
    if (recurso===false) {
      this.setState({
        refresh:recurso,
      });
      
    }else{
      
      //console.log(recurso.desarrollos[0].SplashScreen.url);
        var data= recurso;
      this.setState({
        data: data.desarrollos[0],
      });
      localStorage.setItem(
        "color",
       data.desarrollos[0].identidad.ColorPrimario
      );
      
    
      localStorage.setItem(
        "colorMenu",
        data.desarrollos[0].identidad.ColorMenu
      );
    
      localStorage.setItem(
        "colorTerciario",
        data.desarrollos[0].identidad.ColorTerciario
      );
    
      // Carga la font desde archivos locales
      var name = data.desarrollos[0].identidad.Font.name.split(".")[0];
    
      var notoSansRegular = new FontFace(
        name,
        "url(" + data.desarrollos[0].identidad.Font.url + " )",
        {
          style: "normal",
          weight: "400",
          display: "block",
        }
      );
      document.fonts.add(notoSansRegular);
      notoSansRegular.load();
    }
    }).catch(function(error) {
      return <Refresh />
    });
  
    
      const requestOptions2 = {
        method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
        };
          fetch(urlRestAPI+"/selector",requestOptions2)
        .then((response) => {
          console.log(response.status);
          if(response.status===200) {
            //console.log(response.json())
            var data= response.json();
            console.log(data)
            return data;
          } else {
           return false;
          }
        })
        .then((recurso) => {
          console.log(recurso)
          if (recurso===false) {
            this.setState({
              refresh:recurso,
            });
            
          }else{
         var data= recurso;
         localStorage.setItem(
          "selector",
          JSON.stringify(data.desarrollos)
        );
         }
        });
      /*data2.then((res) => {
       let desa=res.data.data;
       // console.log(desa.desarrollos.length);
        //setData2(desa.desarrollos);
       //for (let index = 0; index < desa.desarrollos.length; index++) {
        localStorage.setItem(
          "selector",
          JSON.stringify(desa.desarrollos)
        );
         //console.log( desa.desarrollos[index].Nombre);
       
      });*/
    }
    }, 2000);
 
    const requestOptions3 = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/selector",requestOptions3)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       localStorage.setItem(
        "selector",
        JSON.stringify(data.desarrollos)
      );
       }
      });
   
  }
//initial;
/*invocation(initial) {
  //alert('invoked')
  clearTimeout( initial )
  console.log('clic fuera');
  if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    
   
 console.log('clic dentro');
 clearTimeout( initial )
 initial = setTimeout(() => {
  location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
}, 5000)

//this.setState({initial:initial})
console.log(initial)
}
console.log(initial)
this.setState({initial:initial})

}*/



 
  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
    console.log('stopped')
    if (localStorage.getItem('idDesarrollo')!==localStorage.getItem('idDefault')) {
    clearInterval(x); 
    var n=0;
    //setTimeout(() => {
      x = setInterval(function(){
        console.log(n);
        if (n==300000) {
          window.location.href = "https://carzawebapp.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    //}, 1000);
  }

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }
  
  }

  handleCallback = (childData) =>{
    this.setState({data: childData})
}
  

  render() {
    let datos = this.state.data;
    //let datos;
    let refresh =this.state.refresh;
    let time = false;
    var splash= <SplashScreen />;
    let x;
    let n =0;
    let inicio = false;
console.log(datos);
if (refresh == false) {
  return <Refresh />
}
/*if (localStorage.getItem('url')!='null') {
  console.log('localstorage')
  inicio=true;
  return <SplashScreen />;
}*/
   if(datos === undefined){
     
   
         if (localStorage.getItem('url')==='nada') {
          return <Loading />
         }else{
          return <AnimacionLottie parentCallback = {this.handleCallback} />;
         }
        // return <Loading />;
        //return <Loading/>
     
   }else{
   
  
   
    try {
     
      return (
       <div onClick={this.changeScreen}
          className="inicio"
          style={{
            background:
              "url('" + url + localStorage.getItem('background') + "')",
          }}
        >
          {console.log(url + localStorage.getItem('background'))}
         
          <Drawer open={true} data={this.state.data} />
          
          <Suspense>
          <ButtonWithDropDown x={x} data2={this.state.data2} />
          </Suspense>
          <img
            src={url + localStorage.getItem('logo')}
            id="logo"
            alt="logo"
          />
          <div className="legalInicio">
            <Legal />
            
          </div>
          
        </div>
              );
        }catch (error) {
      //console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
  }
}

