import React, { useEffect } from "react";
import { Drawer } from "../components/Drawer";
import { Legal } from "../components/Legal.js";
import { Fallback } from "../components/fallback";
import { url ,urlRestAPI} from "../queries/URL";
import { getNosotros } from "../queries/Queries.js";
import { getBusinessIdentity } from "../queries/Queries.js";
import "../stylesheets/dist/nosotros.css";
import "../stylesheets/dist/main.css";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Loading } from "../components/Loading.js";
import { Refresh } from "../components/Refresh";
import { Inicio } from "./Inicio";
/**
 * Regresa la pantalla de NOSOTROS
 * @param  {object}   data    Guarda la información recibida por la petición
 * @return {Component}        Componente de la pantalla NOSOTROS
 */
 var n=0;
 //var x=null; 
export class Nosotros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      refresh:undefined
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.method = new Inicio();
  }

  /**
   * Hace la petición y gurda la respuesta en data
   * Restablece los valores por defecto de la pantalla MODELOS
   */
  componentDidMount() {
    //var data2 = getBusinessIdentity(this.props.match.params.desarrollo);
    /*if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
      n=0;
      x = setInterval(function(){
        console.log(n);
        
        n++;
      },1000);
    }*/
    //console.log(window.x)
    localStorage.setItem("torre", 0);
    localStorage.setItem("prototipo", 0);
    localStorage.setItem("tab", 0);
    const requestOptions = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/nosotros",requestOptions)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data: data,
      });
    }
      });

    
    /*var data = getNosotros(this.props.match.params.desarrollo);
    data.then((res) => {
      this.setState({
        data: res.data.data,
      });
    });*/

    const requestOptions2 = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/menu",requestOptions2)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data2: data.desarrollos[0],
      });
    }
      });

   /* data2.then((res) => {
      //console.log(res.data.data.desarrollos[0].identidad.Background.url)
      this.setState({
        data2: res.data.data.desarrollos[0],
      });
    });*/
  }
  

  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
    console.log('stopped')
  /*  if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    clearInterval(window.x); 
    var n=0;
    //setTimeout(() => {
      /*window.x = setInterval(function(){
        console.log(n);
        if (n==30) {
          location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    //}, 1000);
  }

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }*/
  this.method.changeScreen();
  
  }

  render() {
    let datos = this.state.data;
    let refresh =this.state.refresh;
    console.log(refresh);
    if (refresh == false) {
      return <Refresh />
    }
    if(datos === undefined){
        return <Loading />
    }
    try {
      let leftImage=null;
      if (localStorage.getItem("colorTerciario").includes('null')) {
        leftImage=<LeftImage
              title={this.state.data.desarrollos[0].nosotros.Titulo}
              heroImg={url + this.state.data.desarrollos[0].nosotros.Imagen.url}
              color={localStorage.getItem("color")}
            />;
      }else{
        leftImage=<LeftImage
              title={this.state.data.desarrollos[0].nosotros.Titulo}
              heroImg={url + this.state.data.desarrollos[0].nosotros.Imagen.url}
              color={localStorage.getItem("colorTerciario")}
            />;
      }
      return (
        <div onClick={this.changeScreen}>
          <Drawer data={this.state.data2}/>
          <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />
          {leftImage}
          <TextRight text={this.state.data.desarrollos[0].nosotros.Descripcion} />
          <div className="legalInicio">
            <Legal />
          </div>
        </div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}

/**
 * Regresa el componente de la imagen izquierda
 * @param  {text}   title     Título de la pantalla
 * @param  {text}   heroImg   Url de la imagen
 * @param  {text}   color     Color primario
 * @return {Component}        Componente de la imagen izquierda
 */
const LeftImage = ({ title, heroImg, color }) => {
  useEffect(() => {
    var background = document.getElementById("leftimage");
    background.style.background = "url(" + heroImg + ")";
  }, [title, heroImg]);

  return (
    <div className="leftimage" id="leftimage">
      <div className="title">
        <svg
          width="86"
          height="5"
          viewBox="0 0 86 5"
          xmlns="http://www.w3.org/2000/svg"
          fill={color}
        >
          <rect width="86" height="5" />
        </svg>
        <h2 style={{ color: `white` }}>{title}</h2>
      </div>
    </div>
  );
};

/**
 * Regresa el componente de la descripción de la parte derecha
 * @param  {text}   text     Título de la pantalla
 * @return {Component}       Descripción de la pantalla NOSOTROS
 */
const TextRight = ({ text }) => {
  return (
    <div className="textright">
      <p className="text">{text}</p>
    </div>
  );
};
