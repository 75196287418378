import React from "react"

/**
 * Regresa una imágen con un tamaño en específico
 * @param  {String}  path    	Url de la imagen
 * @param  {String}  caption  Texto en caso de fallo al cargar
 * @param  {Number}  w       	Width de la imagen
 * @param  {Number}  h				Height de la imagen 
 * @return {Component}  Imagen
 */
export const JustImage = ({ path, caption, w, h }) => {
	let imgStyle = {
		width: w,
		height: h
	}
	return <img src={path} alt={caption} style={imgStyle} className="thumb" />
}