import React from "react";

/**
 * Regresa el componente de los bullets
 * @param  {String}   index   Texto del bullet
 * @return {Component}        Bullet
 */
export const Tile = ({ text,indice}) => {
	return (
		<li key={indice} className="tile">
			<div className="tile-icon"></div>{text}
		</li>
	)
}