import { urlAPI } from "./URL";
import Axios from "axios";

/**
 * Regresa la función para obtener la información de la pantalla Inicio y Drawer
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
export function getBusinessIdentity(desarrollo) {
  var data = JSON.stringify({
    query: `query getBussinesIdentity($desarrollo: ID){
			desarrollos(where: {id: $desarrollo}){
				id
				Nombre
				menu{
					Tabs{
            Disponible
            Nombre
						Link
          }
				}
				identidad{
					Background{
						url
					}
					Logo{
						url
					}
					LogoMenu{
						url
					}
					Font{
						url
						name
					}
					ColorPrimario
					ColorMenu
					ColorTerciario
				}
			}
		}`,
    variables: { desarrollo: desarrollo },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}

/**
 * Regresa la función para obtener la información de la pantalla Nosotros
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
export function getNosotros(desarrollo) {
  var data = JSON.stringify({
    query: `query getNosotros($desarrollo: ID){
			desarrollos(where: {id: $desarrollo}){
				nosotros{
					Titulo
					Descripcion
					Imagen{
						url
					}
				}
			}
	}`,
    variables: { desarrollo: desarrollo },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}

/**
 * Regresa la función para obtener la información de la pantalla Concepto
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
export function getConcepto(desarrollo) {
  var data = JSON.stringify({
    query: `query getConcepto($desarrollo: ID){
			desarrollos(where: {id: $desarrollo}){
				concepto{
					Titulo
					Descripcion
					Media{
						url
						caption
					}
				}
			}
	}`,
    variables: { desarrollo: desarrollo },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}


/**
 * Regresa la función para obtener la información de la pantalla Ubicación
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
export function getUbicacion(desarrollo) {
  var data = JSON.stringify({
    query: `query getUbicacion($desarrollo: ID){
			desarrollos(where: {id: $desarrollo}){
				ubicacion {
					Titulo
					Descripcion
					Imagen {
						url
					}
					Caracteristicas{
						Elemento
					}
				}
			}
		}`,
    variables: { desarrollo: desarrollo },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}


/**
 * Regresa la función para obtener la información de la pantalla Amenidades
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
export function getAmenidades(desarrollo) {
  var data = JSON.stringify({
    query: `query getAmenidades($desarrollo: ID) {
			desarrollos(where: { id: $desarrollo }) {
				Torres {
					id
					Nombre
					amenidade {
						Tabs {
							Tab
							Media {
								Archivo {
									url
								}
								Thumbnail {
									url
								}
								Tipo
								Descripcion
								Url
							}
						}
					}
				}
			}
		}
		`,
    variables: { desarrollo: desarrollo },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}


/**
 * Regresa la función para obtener la información de la pantalla Multimedia
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
export function getMultimedia(desarrollo) {
  var data = JSON.stringify({
    query: `query getMultimedia($desarrollo: ID) {
			desarrollos(where: { id: $desarrollo }) {
				Torres {
					id
					Nombre
					multimedia {
						Tabs {
							Tab
							Media {
								Archivo {
									url
								}
								Thumbnail {
									url
								}
								Tipo
								Descripcion
								Url
							}
						}
					}
				}
			}
		}
		`,
    variables: { desarrollo: desarrollo },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}


/**
 * Regresa la función para obtener la información de la pantalla Masteplan
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
export function getMasterplan(desarrollo) {
  var data = JSON.stringify({
    query: `query getMasterplan($desarrollo: ID){
			desarrollos(where: {id: $desarrollo}){
				Torres{
					id
					Nombre
					masterplan{
						Descripcion
						Imagen{
							url
							caption
						}
					}
				}
			}
		}`,
    variables: { desarrollo: desarrollo },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}


/**
 * Regresa la función para obtener la información de la pantalla Modelos
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
export function getModelos(desarrollo) {
  var data = JSON.stringify({
    query: `query getModelos($desarrollo: ID) {
			desarrollos(where: { id: $desarrollo }) {
				id
				Nombre
				Filtrado
				Modelos
				Torres {
					id
					Nombre
					Negritas
					Descripcion
					BotonModelosDisponibilidad
					Caracteristicas {
						Elemento
					}
					Slider{
						url
						caption
					}
					Imagen{
						url
						caption
					}
					prototipos(where: { Disponible: true }, sort: "Recamaras:asc") {
						id
						Nombre
						Negritas
						Descripcion
						Caracteristicas {
							Elemento
						}
						Recamaras
						Niveles {
							Nombre
							Negritas
							Descripcion
							Caracteristicas {
								Elemento
							}
						}
						Slider {
							url
							caption
						}
						Plantas {
							url
							caption
						}
						Disponible
					}
				}
			}
		}
		`,
    variables: { desarrollo: desarrollo },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}


/**
 * Regresa la función para obtener la información de la pantalla Inicio
 * @param  {Number} 		desarrollo ID del desarrollo
 * @param  {Number} 		torre			 ID de la torre
 * @return {Component}	   				 Función para obtener la información
 */
export function getDisponibilidad(desarrollo, torre) {
  var data = JSON.stringify({
    query: `query getDisponibilidad($desarrollo: ID, $torre: ID) {
			desarrollos(where: { id: $desarrollo }) {
				id
				Filtrado
				Torres(where: { id: $torre }) {
					Nombre
					Izquierda
					Derecha
					Geojson {
						url
					}
					Disponibilidad
					ZoomControl
					DistribucionVertical
					NoDisponible
					NoFiltrado
					Disponible
					Separado
					Vendido
				}
				identidad{
					Font{
						url
						name
					}
				}
			}
		}`,
    variables: { desarrollo: desarrollo, torre: torre },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}


/**
 * Regresa la función para obtener la información de la pantalla Inicio
 * @param  {Number} 		desarrollo ID del desarrollo
 * @param  {Number} 		torre			 ID de la torre
 * @param  {Number} 		torre			 ID del prototitpo 
 * @param  {String} 		numero		 NUmero del "departamento" 
 * @return {Component}	   				 Función para obtener la información
 */
export function getDepartamento(desarrollo, torre, prototipo, numero) {
	console.log('getDepartamento',{desarrollo, torre, prototipo, numero});
  var data = JSON.stringify({
    query: `query getDepartamento($desarrollo: ID, $torre: ID, $prototipo: ID, $numero: ID) {
			desarrollos(where: { id: $desarrollo }) {
				id
				Filtrado
				Nombre
				Deptos
				Torres(where: { id: $torre }) {
					Nombre
					Disponibilidad
					prototipos(where: { id: $prototipo }) {
						Nombre
						Negritas
						DescripcionDepartamentos
						Caracteristicas {
							Elemento
						}
						Recamaras
						Slider {
							url
							caption
						}
						Plantas {
							url
							caption
						}
						Incluido {
							Nombre
							Incluido {
								Elemento
							}
						}
						Multimedia {
							Archivo {
								url
							}
							Thumbnail {
								url
							}
							Tipo
							Descripcion
							Url
						}
						departamentos(where: { Num_Lote_Capi: $numero }) {
							id
							Numero
							Num_Lote_Enkontrol
							Num_Lote_Capi
							Nivel
							Caracteristicas {
								Elemento
							}
							Slider {
								url
								caption
							}
							Planta {
								url
								caption
							}
							Vistas {
								url
								caption
							}
							Multimedia {
								Archivo {
									url
								}
								Thumbnail {
									url
								}
								Tipo
								Descripcion
								Url
							}
							Tabs {
								InfoGeneral
								Planta
								Vistas
								Incluido
								Multimedia
							}
						}
					}
				}
				identidad {
					Font {
						url
						name
					}
				}
			}
		}
		`,
    variables: {
      desarrollo: desarrollo,
      torre: torre,
      prototipo: prototipo,
      numero: numero,
    },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}


export async function getDepartamentoByIdCapi(idCapi){
	const departamento = await Axios(`${urlAPI}/departamentos?Num_Lote_Capi=${idCapi}`)
	console.log('getDepartamentoByIdCapi', departamento);
	return departamento
}


export function getPrototipos(desarrollo, torre, prototipo=1, numero) {
	console.log('getPrototipos',{desarrollo, torre, prototipo, numero});
  var data = JSON.stringify({
    query: `query getDepartamento($desarrollo: ID, $torre: ID, $prototipo: ID, $numero: ID) {
			departamentos(where: {
				OR: [
					{ Num_Lote_Capi: $numero }
					{ Num_Lote_Capi: $desarrollo }
					{ Num_Lote_Capi: $torre }
					{ Num_Lote_Capi: $prototipo }
				  ],
				 }){
				prototipo{
					id
				}
			}
		}
		`,
    variables: {
      desarrollo: desarrollo,
      torre: torre,
      prototipo: prototipo,
      numero: numero,
    },
  });

  var config = {
    method: "post",
    url: urlAPI + "/graphql",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return Axios(config);
}

/**
 * query de desarollos
 * @param  {Number} 		desarrollo ID del desarrollo
 * @return {Component}	   				 Función para obtener la información
 */
 export function getDesarrollo(limit) {
	var data = JSON.stringify({
	  query: `query getDesarrollo($limit: Int!){ 
		desarrollos(limit: $limit){
		id
		Nombre
		Filtrado
	}
}`,
variables: {
	limit: limit,
	
  },
	});
  
	var config = {
	  method: "post",
	  url: urlAPI + "/graphql",
	  headers: {
		"Content-Type": "application/json",
	  },
	  data: data,
	};
  
	return Axios(config);
  }
  