import React, { createRef } from "react";
import ImageGallery from "react-image-gallery";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  LeftNav,
  RightNav,
  Minimize,
  FullScreen,
  Times,
} from "../components/Icons";

/**
 * Regresa el Slider de la pantalla CONCEPTO
 * @param  {Number}   index   Indice de la imagen en la cual se encuentra la Galería
 * @param  {Array}    arr     Arreglo de imágenes tal cual vienen desde el panel y se utiliza para thumbnails
 * @param  {Array}    tmp     Arreglo con las imágenes procesadas para la utilización dentro del componente galería y se utiliza para el modo fullscreen
 * @param  {Boolean}  fullcustom    Estado de la pantalla fullscreen(total-completo)
 * @param  {Boolen}   swipe     Si se puede hacer o no swipe
 * @return {Component}        Regresa un Slider
 */
export class SliderModelos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      arr: [],
      tmp: [],
      fullcustom: false,
      swipe: false,
    };

    this.myrenderFullscreenButton = this.myrenderFullscreenButton.bind(this);
    this.myrenderLeftNav = this.myrenderLeftNav.bind(this);
    this.myrenderRightNav = this.myrenderRightNav.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    this._myImageGalleryRenderer = this._myImageGalleryRenderer.bind(this);

    this.fullscreen = createRef();
  }

  componentDidMount() {
    //Detecta el click en las imágenes para pasar al modo fullscreen
    window.addEventListener("keydown", (event) => {
      try {
        // eslint-disable-next-line
        if (event.key == "Escape") {
          this.handleClose();
        }
      } catch (error) {
        console.error(error);
      }
    });
    // Detecta que no esté activos los botones
    document.getElementById("times").style.display = "none";
    document.getElementById("min").style.display = "none";
    // En caso de hacer click en las imagenes
    document.body.addEventListener("click", (event) => {
      if (event.target.tagName === "IMG" && event.target.className === "ima") {
        this.handleFullscreen();
      }
    });
  }

  /**
   * Maneja los errores que provienen de los archivos del arreglo tmp
   */
  _handleImageError(event) {
    if (
      this.props.defaultImage &&
      event.target.src.indexOf(this.props.defaultImage) === -1
    ) {
      event.target.src = this.props.defaultImage;
    }
  }

  /**
   * Renderiza la galería dependiendo el tipo del archivo y en que modo de fullscreen se encuentra
   */
  _myImageGalleryRenderer(item) {
    // your own image error handling
    const onImageError = this._handleImageError;

    // Cambia el color de fondo dependiendo el modo de fullscreen en el que estes
    if (this.state.fullcustom) {
      try {
        var element = document.getElementsByClassName(
          "image-gallery-slides"
        )[0];
        element.id = "full";
        document.getElementById("full").style.background = "black";
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        document.getElementById("full").style.background = "transparent";
      } catch (error) {
        // console.error(error)
      }
    }

    var numero = "";
    var i;
    var prueba;
  for (i = 1; i < 11; i++) {
      numero += i;
      prueba = numero;}

    // Modo fullscren ( no total)
    if (this.state.full && !this.state.fullcustom) {
    
      return (
        <div className="planta">
          <TransformWrapper>
            <TransformComponent>
              <img
                src={item.original}
                alt={item.originalAlt}
                srcSet={item.srcSet}
                sizes={item.sizes}
                onLoad={this.props.onImageLoad}
                onError={onImageError.bind(this)}
                className="ima img-fluid"
                id={numero}
              />
            </TransformComponent>
          </TransformWrapper>

          {item.description && !this.state.fullcustom && (
            <span className="description">
              {item.description}
              {item.link && item.linkLabel && (
                <a className="my-own-class" href={item.link}>
                  {item.linkLabel}
                </a>
              )}
            </span>
          )}
        </div>
      );
    }

    // Modo fullscreen total
    if (this.state.fullcustom) {
      return (
        <div
          style={{
            position: `relative`,
            margin: `40px auto`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TransformWrapper>
            <TransformComponent>
              <img
                src={item.original}
                alt={item.originalAlt}
                srcSet={item.srcSet}
                sizes={item.sizes}
                onLoad={this.props.onImageLoad}
                onError={onImageError.bind(this)}
                // className='image-gallery-image'
                style={{ width: `auto`, height: `1000px`, margin: `auto` }}
              />
            </TransformComponent>
          </TransformWrapper>

          {item.description && !this.state.fullcustom && (
            <span className="description">
              {item.description}
              {item.link && item.linkLabel && (
                <a className="my-own-class" href={item.link}>
                  {item.linkLabel}
                </a>
              )}
            </span>
          )}
        </div>
      );
    } else {
      // Caso por defecto
      return (
        <div>
          <img
            src={item.original}
            alt={item.originalAlt}
            srcSet={item.srcSet}
            sizes={item.sizes}
            onLoad={this.props.onImageLoad}
            onError={onImageError.bind(this)}
            className="ima"
            style={{
              width: `80%`,
              height: `auto`,
              maxHeight: "500px",
              minHeight: "500px",
            }}
          />

          {item.description && !this.state.fullcustom && this.state.full && (
            <span className="description">
              {item.description}
              {item.link && item.linkLabel && (
                <a className="my-own-class" href={item.link}>
                  {item.linkLabel}
                </a>
              )}
            </span>
          )}
        </div>
      );
    }
  }

  /**
   * Maneja la entrada al modo fullscreen(no total)
   */
  handleFullscreen() {
    document.getElementById("times").style.display = "block";
    document.getElementById("min").style.display = "block";

    this.setState(
      {
        full: true,
        swipe: true,
      },
      () => this.fullscreen.current.fullScreen()
    );
  }

  /**
   * Renderiza nuestro botón izquierdo de nuestra galería
   */
  myrenderLeftNav(onClick, disabled) {
    return (
      <LeftNav
        disabled={disabled}
        onClick={onClick}
        full={this.state.full}
        fullcustom={this.state.fullcustom}
      />
    );
  }

  /**
   * Renderiza nuestro botón derecho de nuestra galería
   */
  myrenderRightNav(onClick, disabled) {
    return (
      <RightNav
        disabled={disabled}
        onClick={onClick}
        full={this.state.full}
        fullcustom={this.state.fullcustom}
      />
    );
  }

  /**
   * Maneja el cierre del modo fullscreen
   */
  handleClose(e, onClick) {
    this.fullscreen.current.exitFullScreen();
    document.getElementById("times").style.display = "none";
    document.getElementById("min").style.display = "none";
    this.setState(
      {
        full: false,
        fullcustom: false,
        swipe: false,
      },
      () => (document.getElementById("min").style.display = "none")
    );
  }

  /**
   * Renderiza nuestros botones de la esquina superior derecha de nuestra galería
   */
  myrenderFullscreenButton(onClick, isFullscreen) {
    return (
      <div>
        {this.state.full && !this.state.fullcustom ? (
          <p className="counter">
            {this.state.index + 1 + " / " + this.props.images.length}
          </p>
        ) : null}
        {this.state.fullcustom ? (
          <Minimize
            onClick={(e) =>
              this.setState({ fullcustom: !this.state.fullcustom })
            }
          />
        ) : (
          <FullScreen
            onClick={(e) =>
              this.setState({ fullcustom: !this.state.fullcustom })
            }
          />
        )}

        <Times onClick={(e) => this.handleClose(e, onClick)} />
      </div>
    );
  }

  render() {
    return (
      <div>
        <ImageGallery
          items={this.props.images}
          onSlide={(e) => this.setState({ index: e })}
          startIndex={this.state.index}
          ref={this.fullscreen}
          showPlayButton={false}
          showThumbnails={false}
          renderFullscreenButton={this.myrenderFullscreenButton}
          renderLeftNav={this.myrenderLeftNav}
          renderRightNav={this.myrenderRightNav}
          renderItem={this._myImageGalleryRenderer}
          useBrowserFullscreen={false}
        />
        <p className="slider-counter">
          {this.state.index + 1} / {this.props.images.length}
        </p>
      </div>
    );
  }
}
