import React from "react";
import { Title } from "../components/Title";
import { Slider } from "../components/Slider";
import { Legal } from "../components/Legal";
import { Fallback } from "../components/fallback";
import { url,urlRestAPI } from "../queries/URL";
import { getConcepto,getBusinessIdentity } from "../queries/Queries.js";
import { Drawer } from "../components/Drawer.js";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Loading } from "../components/Loading.js";
import { Refresh } from "../components/Refresh";
import { Inicio } from "./Inicio";

import "../stylesheets/dist/concepto.css";
import "../stylesheets/dist/main.css";

/**
 * Regresa el componente de la pantalla CONCEPTO
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Array}    images  Arreglo de objetos donde guardamos las imágenes con la estructura necesaria para el componente Slider
 * @return {Component}        Componente de la pantalla CONCEPTO
 */
 var n=0;
 var x; 
 clearInterval(x);
export class Concepto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      images: [],
      refresh:undefined,
      initial: undefined,
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.method = new Inicio();
  }

  /**
   * Hace la petición y guarda la respuesta en data
   * Restablece los valores por defecto de la pantalla MODELOS
   * Procesa las imágenes para obtener la estructura del componente Slider
   */
  componentDidMount() {
    localStorage.setItem("torre", 0);
    localStorage.setItem("prototipo", 0);
    localStorage.setItem("tab", 0);
    
    //var data2 = getBusinessIdentity(this.props.match.params.desarrollo);

    //var data = getConcepto(this.props.match.params.desarrollo);
    /*if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
      n=0;
      x = setInterval(function(){
        console.log(n);
        
        n++;
      },1000);
    }*/
   
    const requestOptions = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/concepto",requestOptions)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }    
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data: data.desarrollos[0],
      });
      try {
        var foo = data.desarrollos[0].concepto.Media;

        for (let index = 0; index < foo.length; index++) {
          let aux = {};
          aux.original = url + foo[index].url;
          aux.thumbnail = url + foo[index].url;
          aux.description = foo[index].caption;

          this.setState((prev) => ({
            images: [...prev.images, aux],
          }));
        }
      } catch (error) {}
    }
      });

    /*data.then((res) => {
      this.setState({
        data: res.data.data.desarrollos[0],
      });

      // Procesa la información de las imágenes para dar la estructura necesaria para el componente Slider
      try {
        var foo = res.data.data.desarrollos[0].concepto.Media;

        for (let index = 0; index < foo.length; index++) {
          let aux = {};
          aux.original = url + foo[index].url;
          aux.thumbnail = url + foo[index].url;
          aux.description = foo[index].caption;

          this.setState((prev) => ({
            images: [...prev.images, aux],
          }));
        }
      } catch (error) {}
    });*/
    const requestOptions2 = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/menu",requestOptions2)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }    
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data2: data.desarrollos[0],
      });
    }
      });


    /*data2.then((res) => {
      //console.log(res.data.data.desarrollos[0].identidad.Background.url)
      this.setState({
        data2: res.data.data.desarrollos[0],
      });
    });*/
  }


  /*invocation(initial) {
    //alert('invoked')
    clearTimeout( initial )
    console.log('clic fuera');
    if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
      
     
   console.log('clic dentro');
   clearTimeout( initial )
   initial=setTimeout(() => {
    location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
  }, 5000)
  
  //this.setState({initial:initial})
  console.log(initial)
  }
  console.log(initial)
  this.setState({initial:initial})
  
  }
  */
   
  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
   /* console.log('stopped')
    if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    clearInterval(x); 
    var n=0;
    setTimeout(() => {
      x = setInterval(function(){
        console.log(n);
        if (n==30) {
          location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    }, 1000);*/
    this.method.changeScreen();
  //}

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }
  
  }
  render() {
    //console.log(this.state.data)
    let datos = this.state.data;
    let refresh =this.state.refresh;
    console.log(refresh);
    if (refresh == false) {
      return <Refresh />
    }
    if(datos === undefined){
        return <Loading />
    }
    try {
      return (
        <div onClick={this.changeScreen}>
        <div className="concepto">
          <Drawer data={this.state.data2}/>
          <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />
          <Title
            title={this.state.data.concepto.Titulo}
            text={this.state.data.concepto.Descripcion}
          />
          <div className="carousel">
            <Slider images={this.state.images} />
            <div
              className="legalSlider"
              style={{ color: localStorage.getItem("color") }}
            >
              <Legal />
            </div>
          </div>
        </div>
        </div>
      );
    } catch (error) {
      //console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}
