import React from "react"

/**
 * Regresa el legal de las pantallas
 * @return {Component}  Texto del legal
 */
export const Legal = () => {
	return (
		<p className="legal">Las imágenes son exclusivamente de carácter ilustrativo. Las especificaciones y demás particularidades están sujetas a modificaciones sin previo aviso por parte del desarrollador/proveedor.</p>
	)
}