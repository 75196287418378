import React, {useEffect} from 'react'
//import Lottie from 'react-lottie';
//let animationData = 'https://pantallas.carza.com/uploads/altana_5a43f294ec.json'
import lottieWeb from "lottie-web";
import { Loading } from './Loading';
import animationData from '../img/lottie_Altana.json';
import { Lottie } from '@crello/react-lottie'
import { render } from 'react-dom';
import { url,urlRestAPI } from "../queries/URL";
import { Refresh } from './Refresh';

class AnimacionLottie extends React.Component {

    /*let color = localStorage.getItem('colorMenu');
    let url = localStorage.getItem('url');
    let screen = false;
    let n=0;
    if (url===null) {
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
      
    }
    //let url = 'https://pantallas.carza.com/uploads/altana_5a43f294ec.json';
   // let url = '../img/cantera.json'
    //console.log(url)
    let url = localStorage.getItem('url');
    if (url!==null) {
      /*setTimeout(() => {
        window.location.reload(false);
      }, 2000)}
      const eventListeners = [
        { eventName: 'onAnimationEnd', callback: () => {console.log('complete render');} }
      ];
    const defaultOptions = {
        loop: true,
        autoplay: true,
        path: 'https://pantallas.carza.com/'+url,
        //eventListeners:eventListeners,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        },
       
      };
      useEffect(() => {
        // code to run after render goes here
        setTimeout(() => {
          console.log('complete render')
        }, 5000);
       
      }, []);
      /*var animation = lottieWeb.loadAnimation({
        container: document.getElementById('container'),
        path: 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/pause/pause.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: "Demo Animation",
      });*/
    /*if (color===null) {
        //window.location.reload(false);
        return;
    }else{

      
    return (
        <div style={{height: '1080px',display: 'flex', alignItems: 'center', backgroundColor:'#fff'}}>
          
      <div style={{margin: '0 auto',display: 'block'}}>
        <Lottie 
          options={defaultOptions}
          height={700}
          width={700}
         eventListeners={eventListeners}
    />
      </div>
      </div>
    );
   // }
}
else{
  return <Loading />;
}*/
//let url = localStorage.getItem('url');



render(){
  const onTrigger = () => {
    this.props.parentCallback("finished");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ desarrollo:  window.location.pathname.substr(1,1)})
  };
    fetch(urlRestAPI+"/menu",requestOptions)
  .then((response) => {
    console.log(response.status);
    if(response.status===200) {
      //console.log(response.json())
      var data= response.json();
      console.log(data)
      return data;
    } else {
     return false;
    }
  })
  .then((recurso) => {
    console.log(recurso)
  if (recurso===false) {
    this.setState({
      refresh:recurso,
    });
    
  }else{
    
    //console.log(recurso.desarrollos[0].SplashScreen.url);
      var data= recurso;
    /*this.setState({
      data: data.desarrollos[0],
    });*/
    this.props.parentCallback(data.desarrollos[0]);
   
  
    // Carga la font desde archivos locales
    
  }
  }).catch(function(error) {
    return <Refresh />
  });

  
    const requestOptions2 = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  window.location.pathname.substr(1,1)})
      };
        fetch(urlRestAPI+"/selector",requestOptions2)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       localStorage.setItem(
        "selector",
        JSON.stringify(data.desarrollos)
      );
       }
      });
  };
  const eventListeners = [
    { name: "loopComplete",
    callback: () => {
      //setTimeout(() => {
        onTrigger();
        localStorage.setItem('pass','true');
        console.log('complete render')
     // }, 2000);
     
     },
  },
  ];
  
  let file ='https://pantallas.carza.com/'+localStorage.getItem('url');
  if (localStorage.getItem('url')===null) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    
  }
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: file,
    //eventListeners:eventListeners,
    
   
  };
  
return(
  <div style={{height: '1080px',display: 'flex', alignItems: 'center', backgroundColor:'#fff'}}>
          
  <div style={{margin: '0 auto',display: 'block'}}>
  <Lottie 
  config={defaultOptions}
  width={1200}
  lottieEventListeners={eventListeners}

  />
  </div>
      </div>
);


}
}

export default AnimacionLottie;