import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Tile } from "../components/Tile";
import { SliderModelos } from "../components/SliderModelos";
import { Legal } from "../components/Legal";
import { url } from "../queries/URL";
import { getDisponibilidad } from "../queries/Queries";
import { Link } from "react-router-dom";

/**
 * Regresa el contenido de la tab Información general
 * @param  {Object}   data    Inforamción general
 * @param  {Object}   params  Información de la url
 * @param  {Array}    images  Imágenes del slider
 * @param  {Object}   sembrado  Información de la disponibilidad de los departamentos
 * @param  {String}   status   Estatus de disponibilidad del departamento
 * @param  {Object}   disponibilidad Estados de disponibilidad
 * @return {Component}  Contenido de la tab
 */
export const InfoGeneral = ({ data, dataComplete,id }) => {
  let params = useParams();
  const [images, setImages] = useState([]);
  const [sembrado, setSembrado] = useState({});
  const [status, setStatus] = useState("");

  // Se obtiene la disponibilidad
  useEffect(() => {
    var data = getDisponibilidad(
      params.desarrollo,
      localStorage.getItem("torreID"),
      localStorage.getItem("prototipoID")
    );
    data.then((res) => {

      var dataSembrado;
      var rawFile = new XMLHttpRequest();
      rawFile.open(
        "GET",
        res.data.data.desarrollos[0].Torres[0].Disponibilidad,
        false
      );
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4) {
          if (rawFile.status === 200 || rawFile.status === 0) {
            var allText = rawFile.responseText;
            dataSembrado = allText;
          }
        }
      };
      rawFile.send(null);

      setSembrado(JSON.parse(dataSembrado).data);
    });
  }, [params.desarrollo]);

  // Se ejecuta cada que cambia la información de sembrado
  useEffect(() => {
    const disponibilidad = {
      D: "Disponible",
      V: "Vendido",
      S: "Separado",
    };

    for (let i = 0; i < sembrado.length; i++) {
      const element = sembrado[i];
      // eslint-disable-next-line
      // console.log(element, data.departamentos[0].Num_Lote_Enkontrol)
      if (element.lote_id == data.departamentos[0].Num_Lote_Capi) {
       // console.log(disponibilidad[element.status]);
        setStatus(disponibilidad[element.status]);
      }
    }
  }, [sembrado, data.departamentos]);

  // Estructura la información de las imágenes para que siga la estructura necesaria del slider
  useEffect(() => {
    try {
      
      var foo = data.Slider.concat(data.departamentos[0].Slider);
      for (let index = 0; index < foo.length; index++) {
        let aux = {};
        aux.original = url + foo[index].url;
        aux.thumbnail = url + foo[index].url;
        aux.description = foo[index].caption;

        setImages((images) => [...images, aux]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);


  var boton = (
    <Link
                    to={{
                      pathname:
                        "/" +
                        params.desarrollo +
                        "/" +
                        localStorage.getItem("torreName").split(" ")
                          .join("")
                          .toLocaleLowerCase() +
                        "/disponibilidad",
                      /*state: {
                        modelo: "Modelo " + modelo + 1,
                        returnTo: link.location.pathname,
                      },*/
                    }}
                    
                   
                  >
                  <button  className="botonConsulta" onClick={()=>{localStorage.setItem("flag","false")}}>
   { id==2|| id==3 || id==6 ? <h1 className="palabraConsulta">Ver disponibilidad de modelo</h1> :<h1 className="palabraConsulta">Ver disponibilidad de privada</h1>}

  </button>

                  </Link>
            
  );


  try {
    return (
      <div className="infogeneral row  ">
        <div className=" col-10 col-md-10 col-lg-5 informacion">
          <div className="flex row">

            {data.Recamaras > 0 && <p className="bold ">
              {dataComplete.Filtrado === "Ninguno"
                ? dataComplete.Deptos + " " + data.departamentos[0].Numero
                : data.Recamaras +
                  (data.Recamaras > 1 ? " recámaras" : " recámara") +
                  " - " +
                  "Nivel " +
                  data.departamentos[0].Nivel}
            </p>}

            <div className="flex col-12 justify-content-start">
                <div className={"icon " + status.toLowerCase()}></div>
                <p className="mr-5">{status}</p>
              
              
            </div>
          </div>
          <p>{data.Negritas}</p>
          <p>{data.DescripcionDepartamentos}</p>
          <p className="bold">Características:</p>
          <ul>
            {data.Caracteristicas.concat(
              data.departamentos[0].Caracteristicas
            ).map((item,i) => (
              <Tile text={item.Elemento} key={"if"+i} />
            ))}
          </ul>
          {/* {localStorage.getItem("flag")==='true'?boton:''} */}
        </div>
        <div className="col-8 col-md-8 col-lg-5" id="slidDepartamento">
          <SliderModelos images={images} />
          <div className="legalDepartamento">
            <Legal />
          </div>
        </div>
        
      </div>
      
    );
  } catch (error) {
    console.log(error);
    return null;
  }
};
