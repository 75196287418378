import React from "react";

const cls = "bg-indigo-700 text-white py-3 px-5 rounded";

const Button = ({ onClick }) => (
  <button key={"desarrollos"} className="selector" onClick={onClick}>
   <p className="iconoDesarrollo">Otros desarrollos </p> 
    <svg className="icono" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.7752 16.9786H24.6088C23.9189 16.9786 23.3589 16.4186 23.3589 15.7287V6.56228C23.3589 5.8723 23.9189 5.31232 24.6088 5.31232C25.2988 5.31232 25.8588 5.8723 25.8588 6.56228V14.4787H33.7752C34.4652 14.4787 35.0252 15.0387 35.0252 15.7287C35.0252 16.4186 34.4652 16.9786 33.7752 16.9786Z" fill="#FBFBFB"/>
<path d="M24.9991 16.2507C24.6791 16.2507 24.3591 16.1291 24.1158 15.8841C23.6274 15.3958 23.6274 14.6041 24.1158 14.1158L37.8653 0.366239C38.3536 -0.12208 39.1453 -0.12208 39.6336 0.366239C40.1219 0.854557 40.1219 1.6462 39.6336 2.13452L25.884 15.8841C25.639 16.1291 25.319 16.2507 24.9991 16.2507Z" fill="#FBFBFB"/>
<path d="M28.7491 40H4.58319C2.05494 40 0 37.945 0 35.4168V16.2507C0 14.6158 0.881639 13.1008 2.29993 12.2958C2.90158 11.9542 3.66322 12.1675 4.00321 12.7675C4.34486 13.3675 4.1332 14.1308 3.53322 14.4708C2.88658 14.8374 2.49992 15.5041 2.49992 16.2507V35.4168C2.49992 36.5651 3.43489 37.5001 4.58319 37.5001H28.7491C29.8974 37.5001 30.8324 36.5651 30.8324 35.4168V32.0836C30.8324 31.3936 31.3924 30.8336 32.0823 30.8336C32.7723 30.8336 33.3323 31.3936 33.3323 32.0836V35.4168C33.3323 37.945 31.2774 40 28.7491 40Z" fill="#FBFBFB"/>
<path d="M2.91658 20.0007H1.24996C0.559983 20.0007 0 19.4407 0 18.7507C0 18.0607 0.559983 17.5007 1.24996 17.5007H2.91658C3.60655 17.5007 4.16654 18.0607 4.16654 18.7507C4.16654 19.4407 3.60655 20.0007 2.91658 20.0007Z" fill="#FBFBFB"/>
<path d="M35.4156 28.3337H11.2497C8.72144 28.3337 6.6665 26.2788 6.6665 23.7505V4.58447C6.6665 2.05622 8.72144 0.00128174 11.2497 0.00128174H30.4158C31.1057 0.00128174 31.6657 0.561264 31.6657 1.25124C31.6657 1.94122 31.1057 2.5012 30.4158 2.5012H11.2497C10.1014 2.5012 9.16643 3.43617 9.16643 4.58447V23.7505C9.16643 24.8988 10.1014 25.8338 11.2497 25.8338H35.4156C36.5639 25.8338 37.4989 24.8988 37.4989 23.7505V9.58432C37.4989 8.89434 38.0589 8.33436 38.7488 8.33436C39.4388 8.33436 39.9988 8.89434 39.9988 9.58432V23.7505C39.9988 26.2788 37.9439 28.3337 35.4156 28.3337Z" fill="#FBFBFB"/>
<path d="M18.7495 8.33433H7.91646C7.22649 8.33433 6.6665 7.77435 6.6665 7.08437C6.6665 6.39439 7.22649 5.83441 7.91646 5.83441H18.7495C19.4394 5.83441 19.9994 6.39439 19.9994 7.08437C19.9994 7.77435 19.4394 8.33433 18.7495 8.33433Z" fill="#FBFBFB"/>
</svg>

  </button>
);

export default Button;
