import React from "react";
import { GaleriaVistas } from "./GaleriaVistas";

/**
 * Regresa el contenido de la Tab Vistas
 * @param  {Object}   data   Información de las vistas del "departamento"
 * @return {Component}        Contenido de la tab
 */
export const Vistas = ({ data }) => {

  return (
    <div className="vistaApartamento">
      <GaleriaVistas data={data.Vistas} key={data.Vistas} />)
    </div>
  );
};
