import React from "react";
import { motion } from "framer-motion";
import { Title } from "../../components/Title";
import { InfoGeneral } from "../../components/InfoGeneral";
import { Planta } from "../../components/Planta";
import { Vistas } from "../../components/Vistas";
import { Incluido } from "../../components/Incluido";
import { Multimedia } from "../../components/Multimedia";
import { Breadcrumb } from "../../components/Breadcrumb";
import { Fallback } from "../../components/fallback";
import { getDepartamento } from "../../queries/Queries";
import "../../stylesheets/dist/main.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
/**
 * Arreglo para el match entre la tab y el nombre del switch en el panel para decidir si ocultar la tab
 */
const tabs = [
  { Nombre: "INFORMACIÓN GENERAL", PanelNombre: "InfoGeneral" },
  { Nombre: "PLANTA", PanelNombre: "Planta" },
  { Nombre: "VISTAS", PanelNombre: "Vistas" },
  { Nombre: "INCLUÍDO", PanelNombre: "Incluido" },
  { Nombre: "Multimedia", PanelNombre: "Multimedia" },
];

/**
 * Regresa el componente de la pantalla DEPARTAMENTO
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Number}   tab     Indice de la tab seleccionada
 * @return {Component}        Componente de la pantalla DEPARTAMENTO
 */
export class DepartamentoResponsive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      tab: 0,
    };
  }

  /**
   * Hace la peticion y guarda la respuesta en data.
   */
  componentDidMount() {
    var data = getDepartamento(
      this.props.match.params.desarrollo,
      localStorage.getItem("torreID"),
      localStorage.getItem("prototipoID"),
      this.props.match.params.id
    );
    data.then((res) => {
      this.setState({
        data: res.data.data,
      });

      // Carga la fuente ya que la pantalla no tiene menú
      var name = res.data.data.desarrollos[0].identidad.Font.name.split(".")[0];
      var notoSansRegular = new FontFace(
        name,
        "url(/" + res.data.data.desarrollos[0].identidad.Font.name + ")",
        {
          style: "normal",
          weight: "400",
          display: "block",
        }
      );
      document.fonts.add(notoSansRegular);
      notoSansRegular.load();
    });
  }

  render() {
    try {
      console.log(this.state.data.desarrollos[0].Torres[0].prototipos[0])
      const id = this.props.match.params.id;
      localStorage.setItem(
        "prototipoName",
        this.state.data.desarrollos[0].Torres[0].prototipos[0].Nombre
      );
      return (
        <motion.div
          exit={{ x: -1920 }}
          animate={{ x: 0 }}
          initial={{ x: 1920 }}
          transition={{ duration: 0.3 }}
        >
          {/* Aplica el font a todos los elementos de la pantalla  */}
          <style
            dangerouslySetInnerHTML={{
              __html:
                `
  * { 
  font-family: ` +
                this.state.data.desarrollos[0].identidad.Font.name.split(
                  "."
                )[0] +
                ` !important }
`,
            }}
          />
          <div className="departamento">
            <Breadcrumb
              lk={
                "/" +
                this.props.match.params.desarrollo +
                "/" +
                localStorage
                  .getItem("torreName")
                  .split(" ")
                  .join("")
                  .toLocaleLowerCase() +
                "/disponibilidad"
              }
              tabs={[
                // eslint-disable-next-line
                this.state.data.desarrollos[0].Filtrado == "Ninguno"
                  ? localStorage.getItem("torreName")
                  : this.state.data.desarrollos[0].Torres[0].prototipos[0]
                      .Nombre
                ,
                "Disponibilidad",
                "Departamento " + this.state.data.desarrollos[0].Torres[0].prototipos[0].departamentos[0].Numero,
              ]}
              links={[
                "/" + this.props.match.params.desarrollo + "/modelos",
                "/" +
                  this.props.match.params.desarrollo +
                  "/" +
                  localStorage
                    .getItem("torreName")
                    .split(" ")
                    .join("")
                    .toLocaleLowerCase() +
                  "/disponibilidad",
                "/departamento/" + id,
              ]}
            />
        <div className="container">
           
            <Title
              title={this.state.data.desarrollos[0].Deptos + " " + this.state.data.desarrollos[0].Torres[0].prototipos[0].departamentos[0].Numero}
              top={120}
            />
            
            <div className="navigation depa row ">
                <div className="contentDepartamento d-flex justify-content-center">
              <ul className="scrollDepartamento col-12">
                {/* Muestra las tabs */}
                {tabs.map((item, index) => {
                  // En caso de que el switch no este activado, muestra la tab en la pantalla
                  if (
                    !this.state.data.desarrollos[0].Torres[0].prototipos[0]
                      .departamentos[0].Tabs[item.PanelNombre]
                  ) {
                    try {
                      return (
                        <li
                          key={item.Nombre}
                          onClick={(e) => this.setState({ tab: index })}
                          // eslint-disable-next-line
                          className={
                            // eslint-disable-next-line
                            this.state.tab == index ? "selected-nav" : ""
                          }
                          style={{
                            color:
                              // eslint-disable-next-line
                              this.state.tab == index
                                ? localStorage.getItem("color")
                                : "",
                            borderBottom:
                              // eslint-disable-next-line
                              this.state.tab == index
                                ? "2px solid " + localStorage.getItem("color")
                                : "",
                          }}
                        >
                          {item.Nombre}
                        </li>
                      );
                    } catch (error) {
                      console.log(error);
                      return null;
                    }
                  }
                  return null
                })}
              </ul>
              </div>
              {/* Muestra el contenido para cada tab dependiendo de su index */}
              <RenderTab
                tab={this.state.tab}
                data={this.state.data.desarrollos[0].Torres[0].prototipos[0]}
                dataComplete={this.state.data.desarrollos[0]}
              />
            </div>
          </div>
          </div>
        </motion.div>
      );
    } catch (error) {
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}

/**
 * Regresa el componente del contenido a mostrar dependiendo del index
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Number}   tab     Indice de la tab seleccionada
 * @return {Component}        Componente del contenido de cada tab
 */
const RenderTab = ({ tab, data, dataComplete }) => {
  switch (tab) {
    case 0:
      return <InfoGeneral data={data} dataComplete={dataComplete}/>;
      // eslint-disable-next-line
      break;
    case 1:
      return <Planta data={data} />;
      // eslint-disable-next-line
      break;
    case 2:
      return <Vistas data={data.departamentos[0]} />;
      // eslint-disable-next-line
      break;
    case 3:
      return <Incluido data={data} />;
      // eslint-disable-next-line
      break;
    case 4:
      return <Multimedia data={data} />;
      // eslint-disable-next-line
      break;
    default:
      return <div></div>;
      // eslint-disable-next-line
      break;
  }
};
