import React from "react";
import { Legal } from "../components/Legal";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
/**
 * Regresa las listas con sus bullets de la parte de incluído
 * @param  {Object}     data       Información del prototipo y departamento
 * @return {Component}  Listas de incluído
 */
export const Incluido = ({ data }) => {
  data = data.Incluido;
  try {
    return (
      <div  id="incluidoaux">
        <div className="incluido">
          {data.map((item, index) => (
            <div key={"div"+index}>
              <p className="bold">{item.Nombre}</p>
              <ul key={index+"ul"}>
                {item.Incluido.map((elem,index) => (
                  <li key={item + elem+index}>{elem.Elemento}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="mt-5 legalIncluido">
          <Legal />
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};
