import React from "react";
import { Link, useParams } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";

/**
 * Regresa el componente de la pantalla Fallback(cuando hay errores)
 * @param  {object}   params    Obtiene la información de la url
 * @return {Component}        Componente de la pantalla FALLBACK
 */
export const Loading = () => {
  return (
    <div>
    <div id="loading">
      <div>
      <CircleSpinner size={150} color="#C07D00" loading={true} />;
         
      </div>

    </div>
    <div className="textoLoading">
    <p className="cargando">Cargando información...</p>
</div>
</div>
  );
};
