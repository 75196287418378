import React from "react";
import { Drawer } from "../../components/Drawer.js";
import { Title } from "../../components/Title";
import { GaleriaCustom } from "../../components/GaleriaCustom";
import { Fallback } from "../../components/fallback";
import { getAmenidades } from "../../queries/Queries.js";
import "../../stylesheets/dist/main.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
/**
 * Regresa el componente de la pantalla AMENIDADES
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Number}   torre   Torre seleccionada
 * @param  {Number}   tab     Tab seleccionadada
 * @return {Component}        Componente de la pantalla AMENIDADES
 */
export class AmenidadesResponsive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      torre: 0,
      tab: 0,
    };

    this.handleTorre = this.handleTorre.bind(this);
  }

  /**
   * Hace la petición y la guarda la respuesta en data
   */
  componentDidMount() {
    var data = getAmenidades(this.props.match.params.desarrollo);
    data.then((res) => {
      this.setState(
        {
          data: res.data.data,
        },
        // Busca la primer tab que tenga elementos
        () => this.handleTorre(this.state.torre)
      );
    });
  }

  /**
   * Realiza la búsqueda de las tabs para elegir la primera que tiene elementos
   * @param  {object}   index    Número de la torre dónde se va a a buscar la primera tab que tiene elementos
   * @return {Number}            Regresa el índice de la primera tab con elementos
   */
  handleTorre(index) {
    try {
      for (
        let i = 0;
        i < this.state.data.desarrollos[0].Torres[index].amenidade.Tabs.length;
        i++
      ) {
        const element = this.state.data.desarrollos[0].Torres[index].amenidade
          .Tabs[i];
        if (element.Media.length > 0) {
          console.log(i);
          return i;
        }
      }
    } catch (error) {
      return 0;
    }
  }

  render() {
    try {
      var data = this.state.data.desarrollos[0].Torres;
      return (
        <div className="multimedia">
        
          <Title title={"Amenidades"} />
          <ul id="verticalTab">
            {/* En caso de ser solo una torre no despliega el menú de torres */}
            {data.length > 1 &&
              data.map((item, index) => {
                // En caso de que la torre no tenga amenidades
                if (item.amenidade === null) {
                  return null;
                }
                // Muestra la torre
                return (
                  <li
                    key={item.Nombre}
                    onClick={(e) =>
                      this.setState(
                        {
                          torre: index,
                          tab: this.handleTorre(index),
                        },
                        () => {
                          console.log(
                            "torre: " + this.state.torre,
                            "tab: " + this.state.tab
                          );
                        }
                      )
                    }
                    className={this.state.torre === index ? "sverticaltab" : ""}
                    style={{
                      color:
                        this.state.torre === index
                          ? localStorage.getItem("color")
                          : "",
                    }}
                  >
                    {item.Nombre}
                  </li>
                );
              })}
          </ul>
          <div className={"row navigation " + (data.length > 1 ? "hastabs" : "")}>
          
            <ul className="scroll">
              
              {/* Iteración sobre las tabs disponibles de cada torre  */}
              
              {data[this.state.torre].amenidade.Tabs.map((item, index) => {
                try {
                  // Si tiene elementos muestra la tab
                  if (item.Media.length > 0) {
                  

                    return (
                     
                      <li
                        key={index}
                        onClick={(e) => this.setState({ tab: index })}
                        className={
                          this.state.tab === index ? "selected-nav" : ""
                        }
                        style={{
                          color:
                            this.state.tab === index
                              ? localStorage.getItem("color")
                              : "",
                          borderBottom:
                            this.state.tab === index
                              ? "2px solid " + localStorage.getItem("color")
                              : "",
                        }}
                      >
                        {item.Tab}
                      </li>
                      
                    );
                    
                  }
                  
                  return null;
                } catch (error) {
                  return null;
                }
              })}
            </ul>
            
            {/* Renderiza la tab correspondiente */}
            {data[this.state.torre].amenidade.Tabs[this.state.tab] && (
              <GaleriaCustom
                data={
                  data[this.state.torre].amenidade.Tabs[this.state.tab].Media
                }
                key={this.state.torre + " " + this.state.tab}
              />
            )}
          </div>
          
        </div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}
