/**
 * Regresa el botón izquierdo de las galerías o sliders
 * @param  {Function}   onClick    Función a ejecutar al dar click
 * @param  {Boolean}    disabled   Boolean que nos dice si deshabilitar el botón
 * @param  {Boolean}    full       Nos dice si está en modo fullscreen(no total)
 * @param  {Boolean}    fullcustom Nos dice si está en modo fullscreen(otal)
 * @return {Component}  Botón izquierdo
 */
export const LeftNav = ({ onClick, disabled, full, fullcustom }) => {
  return (
    <svg
      width="30"
      height="55"
      viewBox="0 0 30 55"
      disabled={disabled}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      id="left-nav"
      className="image-gallery-custom-left-nav"
      style={{
        position: `absolute`,
        top: `50%`,
        left: full ? "30px" : "-80px",
        zIndex: 10,
        display: fullcustom ? "none" : "block",
        fill: localStorage.getItem("color"),
      }}
    >
      <path d="M0.627462 25.9909L26.3425 0.594073C27.1938 -0.217932 28.5504 -0.194618 29.3725 0.646156C30.1746 1.46634 30.1746 2.76657 29.3725 3.58663L5.17254 27.4872L29.3725 51.3877C30.2092 52.2141 30.2092 53.5539 29.3725 54.3803C28.5357 55.2066 27.1792 55.2066 26.3425 54.3803L0.627462 28.9835C-0.209156 28.157 -0.209156 26.8173 0.627462 25.9909Z" />
    </svg>
  );
};

/**
 * Regresa el botón derecho de las galerías o sliders
 * @param  {Function}   onClick    Función a ejecutar al dar click
 * @param  {Boolean}    disabled   Boolean que nos dice si deshabilitar el botón
 * @param  {Boolean}    full       Nos dice si está en modo fullscreen(no total)
 * @param  {Boolean}    fullcustom Nos dice si está en modo fullscreen(otal)
 * @return {Component}  Botón derecho
 */
export const RightNav = ({ onClick, disabled, full, fullcustom }) => {
  return (
    <svg
      width="30"
      height="55"
      disabled={disabled}
      onClick={onClick}
      viewBox="0 0 30 55"
      id="right-nav"
      className="image-gallery-custom-left-nav"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: `absolute`,
        top: `50%`,
        right: full ? "30px" : "-80px",
        zIndex: 10,
        display: fullcustom ? "none" : "block",
        fill: localStorage.getItem("color"),
      }}
    >
      <path d="M29.3725 25.9909L3.65752 0.594073C2.80621 -0.217932 1.44964 -0.194618 0.627463 0.646156C-0.174625 1.46634 -0.174625 2.76657 0.627463 3.58663L24.8275 27.4872L0.627463 51.3877C-0.209154 52.2141 -0.209154 53.5539 0.627463 54.3803C1.46433 55.2066 2.82077 55.2066 3.65752 54.3803L29.3725 28.9835C30.2092 28.157 30.2092 26.8173 29.3725 25.9909Z" />
    </svg>
  );
};

/**
 * Regresa el botón de Cierre de las galerías o sliders en fullscreen
 * @param  {Function}   onClick    Función a ejecutar al dar click
 * @param  {Boolean}    disabled   Boolean que nos dice si deshabilitar el botón
 * @param  {isFullscreen}   isFullscreen       Nos dice si está en modo fullscreen
 * @return {Component}  Botón de cierre
 */
export const Times = ({
  onClick,
  disabled,
  isFullscreen,
}) => {
  return (
    <svg
      width="32"
      height="32"
      disabled={disabled}
      onClick={onClick}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      id="times"
      className={`image-gallery-fullscreen-button${
        isFullscreen ? " active" : ""
      }`}
      style={{
        position: `absolute`,
        top: `48px`,
        right: `30px`,
        zIndex: 10,
        width: `30px`,
        height: `30px`,
        fill: localStorage.getItem("color"),
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.89697 0.497054C2.23424 -0.165672 1.15972 -0.165704 0.49703 0.497076C-0.165569 1.15976 -0.165709 2.23427 0.497074 2.89699L13.6 16L0.497052 29.103C-0.165672 29.7658 -0.165703 30.8403 0.497074 31.503C0.828359 31.8343 1.26354 31.9999 1.69704 31.9999C2.13058 31.9999 2.56572 31.8342 2.89703 31.503L15.9999 18.4L29.1029 31.503C29.4342 31.8342 29.8694 31.9999 30.3029 31.9999C30.7364 31.9999 31.1716 31.8342 31.5029 31.503C32.1656 30.8402 32.1656 29.7657 31.5029 29.103L18.3999 16L31.5029 2.89697C32.1656 2.23424 32.1656 1.15973 31.5029 0.497032C30.8402 -0.165577 29.7657 -0.165732 29.103 0.497076L15.9999 13.6001L2.89697 0.497054Z"
      />
    </svg>
  );
};


/**
 * Regresa el botón de Fullscreen de las galerías o sliders en fullscreen
 * @param  {Function}   onClick    Función a ejecutar al dar click
 * @param  {Boolean}    disabled   Boolean que nos dice si deshabilitar el botón
 * @param  {isFullscreen}   isFullscreen       Nos dice si está en modo fullscreen
 * @return {Component}  Botón de fullscreen
 */
export const FullScreen = ({
  onClick,
  disabled,
  isFullscreen,
}) => {
  return (
    <svg
      width="34"
      height="33"
      disabled={disabled}
      onClick={onClick}
      viewBox="0 0 34 33"
      id="min"
      className={`image-gallery-fullscreen-button${
        isFullscreen ? " active" : ""
      }`}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: `absolute`,
        top: `48px`,
        right: `100px`,
        zIndex: 10,
        width: `30px`,
        height: `30px`,
        fill: localStorage.getItem("color"),
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.14287 0C1.40938 0 0.788719 0.479779 0.57736 1.14287H0.5V10.1429H3.78574V3.28574H10.6429V0H2.14287ZM32.3569 0H23.3569V3.28574H30.2141V10.1429H33.4998V1.64287C33.4998 0.909378 33.02 0.288719 32.3569 0.0773603V0ZM33.4998 22.8569H30.2141V29.7141H23.3569V32.9998H31.8569C32.7648 32.9998 33.4998 32.2648 33.4998 31.3569V22.8569ZM3.78574 22.8569H0.5V31.3569C0.5 32.2648 1.23501 32.9998 2.14287 32.9998H10.6429V29.7141H3.78574V22.8569Z"
      />
    </svg>
  );
};

/**
 * Regresa el botón de Minimizado de las galerías o sliders en fullscreen
 * @param  {Function}   onClick    Función a ejecutar al dar click
 * @param  {Boolean}    disabled   Boolean que nos dice si deshabilitar el botón
 * @param  {isFullscreen}   isFullscreen       Nos dice si está en modo fullscreen
 * @return {Component}  Botón de minimizado
 */
export const Minimize = ({
  onClick,
  disabled,
  isFullscreen,
}) => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      disabled={disabled}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      id="min"
      className={`image-gallery-fullscreen-button${
        isFullscreen ? " active" : ""
      }`}
      style={{
        position: `absolute`,
        top: `48px`,
        right: `100px`,
        zIndex: 10,
        width: `30px`,
        height: `30px`,
        fill: localStorage.getItem("color"),
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5655 9.00002C10.3542 9.66311 9.73351 10.1429 9.00002 10.1429H0.5V6.85715H7.35715V0H10.6429V9.00002H10.5655ZM33.4998 10.1429H24.4998V10.0655C23.8367 9.85417 23.3569 9.23351 23.3569 8.50002V0H26.6427V6.85715H33.4998V10.1429ZM23.3569 32.9998H26.6427V26.1427H33.4998V22.8569H24.9998C24.092 22.8569 23.3569 23.592 23.3569 24.4998V32.9998ZM7.35715 32.9998V26.1427H0.5V22.8569H9.00002C9.90788 22.8569 10.6429 23.592 10.6429 24.4998V32.9998H7.35715Z"
      />
    </svg>
  );
};
