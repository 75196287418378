import React, { createRef } from "react";
import ImageGallery from "react-image-gallery";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  LeftNav,
  RightNav,
  Minimize,
  FullScreen,
  Times,
} from "../components/Icons";
import { Legal } from "../components/Legal";
import { url } from "../queries/URL";
import { image } from "../queries/Consts";
import "../stylesheets/dist/image-gallery.css";
import "../stylesheets/dist/video-react.css";

/**
 * Regresa el componente de las galerías mostrado en AMENIDADES, MULTIMEDIA Y MULTIMEDIA-DEPARTAMENTO
 * @param  {Number}   index   Indice de la imagen en la cual se encuentra la Galería
 * @param  {Array}    arr     Arreglo de imágenes tal cual vienen desde el panel y se utiliza para thumbnails
 * @param  {Array}    tmp     Arreglo con las imágenes procesadas para la utilización dentro del componente galería y se utiliza para el modo fullscreen
 * @param  {Boolean}  full    Estado de la pantalla fullscreen(no total)
 * @param  {Boolean}  fullcustom    Estado de la pantalla fullscreen(total-completo)
 * @return {Component}        Galería con todos los archivos y sus thumbnails
 */
export class GaleriaVistas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      arr: [],
      tmp: [],
      full: false,
      fullcustom: false,
    };

    this.myrenderFullscreenButton = this.myrenderFullscreenButton.bind(this);
    this.myrenderLeftNav = this.myrenderLeftNav.bind(this);
    this.myrenderRightNav = this.myrenderRightNav.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    // this.handleLeft = this.handleLeft.bind(this);
    // this.handleRight = this.handleRight.bind(this);
    this._myImageGalleryRenderer = this._myImageGalleryRenderer.bind(this);
    this.exitHandler = this.exitHandler.bind(this);
    this.myrenderLeftNav = this.myrenderLeftNav.bind(this);
    this.myrenderRightNav = this.myrenderRightNav.bind(this);
    this.fullscreen = createRef();
  }

  componentDidMount() {
    //Detecta el click en las imágenes para pasar al modo fullscreen
    document.body.addEventListener("click", (event) => {
      if (
        event.target.tagName === "IMG" &&
        event.target.className === "image-gallery-image"
      ) {
        this.handleFullscreen();
      }
    });

    document.addEventListener("fullscreenchange", this.exitHandler);
    document.addEventListener("webkitfullscreenchange", this.exitHandler);
    document.addEventListener("mozfullscreenchange", this.exitHandler);
    document.addEventListener("MSFullscreenChange", this.exitHandler);

    try {
      for (let index = 0; index < this.props.data.length; index++) {
        // Arreglo que vamos usar para los thumbnails
        this.setState((prev) => ({
          arr: [...prev.arr, this.props.data[index]],
        }));

        let aux = {};
        aux.original = url + this.props.data[index].url;
        aux.thumbnail = url + this.props.data[index].url;
        aux.description = this.props.data[index].caption;
        aux.type = image;

        // Arreglo para los archivos en modo fullscreen
        this.setState((prev) => ({
          tmp: [...prev.tmp, aux],
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Si no estas en fullscreen hace el cierre de los componentes
   */
  exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      this.handleClose();
    }
  }

  /**
   * Maneja los errores que provienen de los archivos del arreglo tmp
   */
  _handleImageError(event) {
    if (
      this.props.defaultImage &&
      event.target.src.indexOf(this.props.defaultImage) === -1
    ) {
      event.target.src = this.props.defaultImage;
    }
  }

  /**
   * Renderiza la galería dependiendo el tipo del archivo y en que modo de fullscreen se encuentra
   */
  _myImageGalleryRenderer(item) {
    const onImageError = this._handleImageError;

    // Cambia el color de fondo dependiendo el modo de fullscreen en el que estes
    if (this.state.fullcustom) {
      try {
        var element = document.getElementsByClassName(
          "image-gallery-slides"
        )[0];
        element.id = "full";
        document.getElementById("full").style.background = "black";
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        document.getElementById("full").style.background = "transparent";
      } catch (error) {
        // console.error(error)
      }
    }

    // Modo fullscreen(pantalla completa)
    if (this.state.fullcustom) {
      // Caso para imágeenes
      if (item.type === image) {
        return (
          <div
            style={{
              position: "relative",
              margin: "40px auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={item.original}
                  alt={item.originalAlt}
                  srcSet={item.srcSet}
                  sizes={item.sizes}
                  onLoad={this.props.onImageLoad}
                  onError={onImageError.bind(this)}
                  // className='image-gallery-image'
                  style={{ width: `auto`, height: `1000px` }}
                />
              </TransformComponent>
            </TransformWrapper>

            {item.title && (
              <span className="description">
                {item.title}
                {item.link && item.linkLabel && (
                  <a className="my-own-class" href={item.link}>
                    {item.linkLabel}
                  </a>
                )}
              </span>
            )}
          </div>
        );
      }
    } else {
      // Caso para videos(fullscreen no total)
      if (item.type === image) {
        return (
          <div
            style={{
              position: `relative`,
              margin: `138px auto`,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={item.original}
                  alt={item.originalAlt}
                  srcSet={item.srcSet}
                  sizes={item.sizes}
                  onLoad={this.props.onImageLoad}
                  onError={onImageError.bind(this)}
                  // className='image-gallery-image'
                  style={{ width: `auto`, height: "800px" }}
                />
              </TransformComponent>
            </TransformWrapper>

            {item.description && (
              <span className="description">
                {item.description}
                {item.link && item.linkLabel && (
                  <a className="my-own-class" href={item.link}>
                    {item.linkLabel}
                  </a>
                )}
              </span>
            )}
          </div>
        );
      }
    }

    // Caso por defecto por si no es de ningún tipo de archivos.
    return (
      <div>
        <img
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          onLoad={this.props.onImageLoad}
          onError={onImageError.bind(this)}
        />

        {item.description && (
          <span className="image-gallery-description">
            {item.description}
            {item.link && item.linkLabel && (
              <a className="my-own-class" href={item.link}>
                {item.linkLabel}
              </a>
            )}
          </span>
        )}
      </div>
    );
  }

  /**
   * Maneja la entrada al modo fullscreen(no total)
   */
  handleFullscreen(e, position) {
    try {
      this.setState(
        {
          index: position,
          full: true,
        },
        () => {
          this.fullscreen.current.fullScreen();
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Renderiza nuestro botón izquierdo de nuestra galería
   */
  myrenderLeftNav(onClick, disabled) {
    return (
      <LeftNav
        disabled={disabled}
        onClick={onClick}
        full={this.state.full}
        fullcustom={this.state.fullcustom}
      />
    );
  }

  /**
   * Renderiza nuestro botón derecho de nuestra galería
   */
  myrenderRightNav(onClick, disabled) {
    return (
      <RightNav
        disabled={disabled}
        onClick={onClick}
        full={this.state.full}
        fullcustom={this.state.fullcustom}
      />
    );
  }

  /**
   * Maneja el cierre del modo fullscreen
   */
  handleClose(e, onClick) {
    this.setState({
      index: 0,
      fullcustom: false,
      full: false,
    });
  }

  /**
   * Renderiza nuestros botones de la esquina superior derecha de nuestra galería
   */
  myrenderFullscreenButton(onClick, isFullscreen) {
    return (
      <div>
        {this.state.full && !this.state.fullcustom ? (
          <p className="counter">
            {this.state.index + 1 + " / " + this.state.arr.length}
          </p>
        ) : null}
        {this.state.fullcustom ? (
          <Minimize
            onClick={(e) =>
              this.setState({ fullcustom: !this.state.fullcustom })
            }
          />
        ) : (
          <FullScreen
            onClick={(e) =>
              this.setState({ fullcustom: !this.state.fullcustom })
            }
          />
        )}

        <Times onClick={(e) => this.handleClose(e, onClick)} />
      </div>
    );
  }

  render() {
    const listItems = this.state.arr.map((item, position) => {
      // Renderiza las imágenes que aparacen como thumbnails
      return (
        <div key={position}>
          <img
            onClick={(e) => this.handleFullscreen(e, position)}
            src={url + item.url}
            alt={item.descripcion}
            className="thumb"
          />
        </div>
      );
    });
    return (
      <div className="vistas">
        <div id="plantasColumnas">{listItems}</div>
        <div className="legalGaleria">
          <Legal />
        </div>
        {this.state.full && (
          <div id="fullscreen">
            <ImageGallery
              items={this.state.tmp}
              lazyLoad={true}
              onSlide={(e) => this.setState({ index: e })}
              startIndex={this.state.index}
              ref={this.fullscreen}
              showPlayButton={false}
              showThumbnails={false}
              renderFullscreenButton={this.myrenderFullscreenButton}
              renderLeftNav={this.myrenderLeftNav}
              renderRightNav={this.myrenderRightNav}
              renderItem={this._myImageGalleryRenderer.bind(this)}
              useBrowserFullscreen={false}
              disableSwipe={true}
            />
          </div>
        )}
      </div>
    );
  }
}
