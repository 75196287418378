import React from "react"
import { GaleriaVistas } from "./GaleriaVistas"

/**
 * Regresa el contenido de la tab de Planta en "departamento"
 * @param  {Object}   data    Información del "departamento"
 * @return {Component}  Contenido de la tab
 */
export const Planta = ({ data }) => {

	// Concatena la planta del prototipo con la del "departamento"
	data = data.Plantas.concat(data.departamentos[0].Planta)
	return (
		<GaleriaVistas data={data} key={data} />)
}