import React from "react";
import { Legal } from "./Legal";
import { url } from "../queries/URL";
import { Tile } from "./Tile.js";
import { SliderModelos } from "./SliderModelos";
import { Link, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
/**
 * Regresa el componente con la información de los modelos
 * @param  {Object}   data    Información de las torres, modelos, niveles
 * @param  {Object}   link    Información de la url
 * @param  {Number}   torre   Indíce del elemento de la barra vertical seleccionada
 * @param  {Number}   modelo  Indíce del elemento de la primera barra horizontal seleccionada
 * @param  {Number}   tab     Indíce del elemento de la segunda barra horizontal seleccionada(niveles o prototipos)
 * @param  {Function} handleTorre  Función que se ejecuta al cambiar de torre
 * @param  {Function} handleModelo  Función que se ejecuta al cambiar de modelo
 * @param  {Function} handleTab  Función que se ejecuta al cambiar de tab
 * @param  {Function} handleStorage  Función que se ejecuta al dar click en el botón de "ver disponibilidad"
 * @return {Component}        Componente con la información ya procesada
 */
export const Torres = ({
  link,
  data,
  torre,
  modelo,
  tab,
  handleTorre,
  handleModelo,
  handleTab,
  handleStorage,
}) => {
  var params = useParams();

  console.log('Torres data', data);
  try {
    return (
      <div>
        <ul id="verticalTab">
          {/* Muestra las torres en vertical */}
          {data.Torres.length > 1 &&
            data.Torres.map((item, index) => {
              return (
                <li
                  key={torre + item.Nombre}
                  // eslint-disable-next-line
                  className={index == torre ? "sverticaltab" : ""}
                  onClick={(e) => {
                    handleTorre(index);
                  }}
                  style={{
                    // eslint-disable-next-line
                    color: torre == index ? localStorage.getItem("color") : "",
                  }}
                >
                  {item.Nombre}
                </li>
              );
            })}
        </ul>
        {/* Muestra el contenido dependiendo el Filtrado */}
        <Filtrador
          params={params}
          data={data}
          link={link}
          torre={torre}
          modelo={modelo}
          tab={tab}
          handleModelo={handleModelo}
          handleTab={handleTab}
          handleStorage={handleStorage}
        />
      </div>
    );
  } catch (error) {
    console.error(error);
    if (data === undefined) {
      return null;
    }
    return null;
  }
};

/**
 * Regresa el componente con la información de los modelos
 * @param  {Object}   params  Información de la url para navegación despues del click
 * @param  {Object}   data    Información de las torres, modelos, niveles
 * @param  {Object}   link    Información de la url
 * @param  {Number}   torre   Indíce del elemento de la barra vertical seleccionada
 * @param  {Number}   modelo  Indíce del elemento de la primera barra horizontal seleccionada
 * @param  {Number}   tab     Indíce del elemento de la segunda barra horizontal seleccionada(niveles o prototipos)
 * @param  {Function} handleModelo  Función que se ejecuta al cambiar de modelo
 * @param  {Function} handleTab  Función que se ejecuta al cambiar de tab
 * @param  {Function} handleStorage  Función que se ejecuta al dar click en el botón de "ver disponibilidad"
 * @return {Component}        Componente con la información ya procesada
 */
const Filtrador = ({
  params,
  data,
  link,
  torre,
  modelo,
  tab,
  handleModelo,
  handleTab,
  handleStorage,
}) => {
  try {
    console.log("filtradoType", data.Filtrado);
    localStorage.setItem("filtradoType", data.Filtrado);
    // Cuando no se especifica ningun filtrado (valor por defecto)
    try {
      // Cuando el filtrado o agrupación se hace por modelos(prototipos)
      if (data.Filtrado === "Modelos") {
        data.Torres[torre].prototipos.sort(dynamicSort("Nombre"));
  
        // Une las imagenes de la planta y las del slider
        // eslint-disable-next-line
        var images = [];
        var sliderPlantas = data.Torres[torre].prototipos[modelo].Plantas.concat(
          data.Torres[torre].prototipos[modelo].Slider
        );
  
        for (let index = 0; index < sliderPlantas.length; index++) {
          const element = sliderPlantas[index];
          let aux = {};
          aux.original = url + element.url;
          aux.thumbnail = url + element.url;
          aux.description = element.caption;
  
          images.push(aux);
        }
  
        return (
          <div
            className={"navigation " + (data.Torres.length > 1 ? "hastabs" : "")}
          >
            <ul>
              {data.Torres[torre].prototipos.map((item, index) => {
                return (
                  <li
                    key={torre + modelo + item.Nombre + item.index}
                    // eslint-disable-next-line
                    className={index == modelo ? "selected-nav" : ""}
                    onClick={(e) => handleModelo(index, item.id)}
                    style={{
                      color:
                        // eslint-disable-next-line
                        modelo == index ? localStorage.getItem("color") : "",
                      borderBottom:
                        // eslint-disable-next-line
                        modelo == index
                          ? "2px solid " + localStorage.getItem("color")
                          : "",
                    }}
                  >
                    {item.Nombre}
                  </li>
                );
              })}
            </ul>
            <div id="flex">
              <div id="info">
                <ul>
                  {data.Torres[torre].prototipos[modelo].Niveles.length > 0 &&
                    data.Torres[torre].prototipos[modelo].Niveles.map(
                      (item, index) => {
                        return (
                          <li
                            key={torre + modelo + tab + item.Nombre}
                            // eslint-disable-next-line
                            className={index == tab ? "selected-nav" : ""}
                            onClick={(e) => handleTab(index)}
                            style={{
                              color:
                                // eslint-disable-next-line
                                tab == index ? localStorage.getItem("color") : "",
                              borderBottom:
                                // eslint-disable-next-line
                                tab == index
                                  ? "2px solid " + localStorage.getItem("color")
                                  : "",
                            }}
                          >
                            {item.Nombre}
                          </li>
                        );
                      }
                    )}
                </ul>
                <div id="info-overflow">
                  {
                    <Niveles
                      data={data}
                      torre={torre}
                      modelo={modelo}
                      tab={tab}
                    />
                  }
                  {data.Torres[torre].BotonModelosDisponibilidad && (
                    <Link
                      to={{
                        pathname:
                          "/" +
                          params.desarrollo +
                          "/" +
                          data.Torres[torre].Nombre.split(" ")
                            .join("")
                            .toLocaleLowerCase() +
                          "/disponibilidad",
                        state: {
                          modelo: "Modelo " + modelo + 1,
                          returnTo: link.location.pathname,
                        },
                      }}
                      className="boton"
                      onClick={handleStorage()}
                      style={{ backgroundColor: localStorage.getItem("color") }}
                    >
                      Ver disponibilidad
                    </Link>
                  )}
                </div>
              </div>
              <div id="slid">
                <div>
                  {images.length > 0 && (
                    <SliderModelos images={images} key={torre + modelo + tab} />
                  )}
                </div>
                <div
                  className="legalModelos"
                  style={{ color: localStorage.getItem("color") }}
                >
                  <Legal />
                </div>
              </div>
            </div>
          </div>
        );
      }
  
      // Cuando el filtrado o agrupación se hace por recámaras
      if (data.Filtrado === "Recamaras") {
        // Se agrupan por el número de Recamaras
        var prototiposAgrupados = groupBy(
          data.Torres[torre].prototipos,
          data.Filtrado
        );
  
        // Se juntan las imagenes de planta y de slider para cada prototipo
        // eslint-disable-next-line
        var images = [];
        // eslint-disable-next-line
        var sliderPlantas = prototiposAgrupados[modelo][tab].Plantas.concat(
          prototiposAgrupados[modelo][tab].Slider
        );
  
        for (let index = 0; index < sliderPlantas.length; index++) {
          const element = sliderPlantas[index];
          let aux = {};
          aux.original = url + element.url;
          aux.thumbnail = url + element.url;
          aux.description = element.caption;
  
          images.push(aux);
        }
        let filtro;
        /*if (item[0][data.Filtrado]==1) {
          filtro =item[0][data.Filtrado] + "   " + "Recámara";
        }else{
          filtro = item[0][data.Filtrado] + "   " + data.Filtrado;
        }*/
        return (
          <div
            className={" navigation " + (data.Torres.length > 1 ? "hastabs" : "")}
          >
            <div className="d-flex justify-content-center">
            <ul className="scrollModelo d-flex justify-content-center">
              {prototiposAgrupados.map((item, index) => {
                {if (item[0][data.Filtrado]==1) {
                  filtro =item[0][data.Filtrado] + "   " + "Recámara";
                }else{
                  filtro = item[0][data.Filtrado] + "   " + "Recámaras";
                }}
                return (
                
                  <li
                    key={torre + modelo + item[0].Nombre + item[0].index}
                    
                    // eslint-disable-next-line
                    className={index == modelo ? "selected-nav" : ""}
                    onClick={(e) => handleModelo(index)}
                    style={{
                      color:
                        // eslint-disable-next-line
                        modelo == index ? localStorage.getItem("color") : "",
                      borderBottom:
                        // eslint-disable-next-line
                        modelo == index
                          ? "2px solid " + localStorage.getItem("color")
                          : "",
                    }}
                  >
                    {filtro}
                  </li>
                );
              })}
            </ul>
            </div>
            <div className="row d-flex justify-content-center" id="flex">
              <div className="col-12 col-md-12 col-lg-5 " id="info">
                <ul className="scrollInfo" >
                  {prototiposAgrupados[modelo].map((item, index) => {
                    return (
                      <li
                        key={torre + modelo + tab + item.Nombre}
                        // eslint-disable-next-line
                        className={index == tab ? "selected-nav" : ""}
                        onClick={(e) => {
                          handleTab(index);
                          localStorage.setItem("prototipoID", item.id);
                        }}
                        style={{
                          color:
                            // eslint-disable-next-line
                            tab == index ? localStorage.getItem("color") : "",
                          borderBottom:
                            // eslint-disable-next-line
                            tab == index
                              ? "2px solid " + localStorage.getItem("color")
                              : "",
                        }}
                      >
                        {item.Nombre}
                      </li>
                    );
                  })}
                </ul>
                <div  id="info-overflow">
                  {
                    <div className="parrafo">
                      <p>
                        <b>{prototiposAgrupados[modelo][tab].Negritas}</b>
                      </p>
                      <p>{prototiposAgrupados[modelo][tab].Descripcion}</p>
                      <ul id="caracteristicas">
                        {prototiposAgrupados[modelo][tab].Caracteristicas.map(
                          (item,i) => (
                            <Tile text={item.Elemento} key={"t"+i} />
                          )
                        )}
                      </ul>
                    </div>
                  }
                  <div className="d-flex justify-content-center ">
                  {data.Torres[torre].BotonModelosDisponibilidad && (
                    <Link
                      to={{
                        pathname:
                          "/" +
                          params.desarrollo +
                          "/" +
                          data.Torres[torre].Nombre.split(" ")
                            .join("")
                            .toLocaleLowerCase() +
                          "/disponibilidad",
                        state: {
                          modelo: "Modelo " + modelo + 1,
                          returnTo: link.location.pathname,
                        },
                      }}
                      className="boton"
                      onClick={(e) => {
                        localStorage.setItem(
                          "prototipoID",
                          prototiposAgrupados[modelo][tab].id
                        );
                        localStorage.setItem(
                          "prototipoName",
                          prototiposAgrupados[modelo][tab].Nombre
                        );
                        handleStorage();
                      }}
                      style={{ backgroundColor: localStorage.getItem("color") }}
                    >
                      Ver disponibilidad
                    </Link>
                  )}
                  </div>
                </div>
              </div>
              <div className="col-8 col-md-12 col-lg-6" id="slid">
                <div>
                  {images.length > 0 && (
                    <SliderModelos images={images} key={torre + modelo + tab} />
                  )}
                </div>
                <div
                  className="legalModelos"
                  style={{ color: localStorage.getItem("color") }}
                >
                  <Legal />
                </div>
              </div>
            </div>
          </div>
        );
      }
      
    } catch (error) {
      // if (data.Filtrado === "Ninguno") {
        var images = [];
  
        // Busca las imagenes de la torre
        for (let index = 0; index < data.Torres[torre].Slider.length; index++) {
          const element = data.Torres[torre].Slider[index];
          let aux = {};
          aux.original = url + element.url;
          aux.thumbnail = url + element.url;
          aux.description = element.caption;
  
          images.push(aux);
        }
  
        localStorage.setItem("torreID", data.Torres[torre].id);
        localStorage.removeItem("prototipoID")
        return (
          <div className="navigation hastabs">
            <ul></ul>
            <div className="row d-flex justify-content-center" id="flex">
              <div className="col-12 col-md-12 col-lg-5" id="info">
                <ul></ul>
                <div  id="info-overflow">
                  {
                    <div>
                      <p>
                        <b>{data.Torres[torre].Negritas}</b>
                      </p>
                      <p>{data.Torres[torre].Descripcion}</p>
                      <ul id="caracteristicas">
                        {data.Torres[torre].Caracteristicas.map((item,i) => (
                          <Tile text={item.Elemento} key={"t"+i}/>
                        ))}
                      </ul>
                    </div>
                  }
                  <div className="d-flex justify-content-center ">
                  {data.Torres[torre].BotonModelosDisponibilidad && (
                    <Link
                      to={{
                        pathname:
                          "/" +
                          params.desarrollo +
                          "/" +
                          data.Torres[torre].Nombre.split(" ")
                            .join("")
                            .toLocaleLowerCase() +
                          "/disponibilidad",
                        state: {
                          modelo: "Modelo " + modelo + 1,
                          returnTo: link.location.pathname,
                        },
                      }}
                      className="boton"
                      onClick={handleStorage()}
                      style={{ backgroundColor: localStorage.getItem("color") }}
                    >
                      Ver disponibilidad
                    </Link>
                  )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6"  id="slid">
                <div>
                  {images.length > 0 && (
                    <SliderModelos images={images} key={torre + modelo + tab} />
                  )}
                </div>
                <div
                  className="legalModelos"
                  style={{ color: localStorage.getItem("color") }}
                >
                  <Legal />
                </div>
              </div>
            </div>
          </div>
        );
      // }
    }


    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * Agrupa por alguna propiedad dentro de la colección
 * @param  {Array}   collection    Arreglo a filtrar
 * @param  {String}  property      Propiedad con la que filtramos el arreglo
 * @return {Component}        La coleccion filtrada por propiedad
 */
function groupBy(collection, property) {
  var i = 0,
    val,
    index,
    values = [],
    result = [];
  for (; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  return result;
}

/**
 * Ordena un arreglo de objects por una propiedad
 * @param  {String}  property      Propiedad con la que filtramos el arreglo
 * @return {Component}        Arreglo ordenado por la propiedad
 */
function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

/**
 * Componente cuando el prototipo tiene niveles
 * @param  {Object}   data    Inforamcion de el contenido a mostrar
 * @param  {Number}   torre   Indíce del elemento de la barra vertical seleccionada
 * @param  {Number}   modelo  Indíce del elemento de la primera barra horizontal seleccionada
 * @param  {Number}   tab     Indíce del elemento de la segunda barra horizontal seleccionada(niveles o prototipos)
 * @return {Component}        Componente con niveles
 */
const Niveles = ({ data, torre, modelo, tab }) => {
  if (data.Torres[torre].prototipos[modelo].Niveles.length > 0) {
    return (
      <div>
        <p>
          <b>{data.Torres[torre].prototipos[modelo].Niveles[tab].Negritas}</b>
        </p>
        <p>{data.Torres[torre].prototipos[modelo].Niveles[tab].Descripcion}</p>
        <ul id="caracteristicas">
          {data.Torres[torre].prototipos[modelo].Niveles[
            tab
          ].Caracteristicas.map((item,i) => (
            <Tile text={item.Elemento} key={"t"+i}/>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div>
      <p>
        <b>{data.Torres[torre].prototipos[modelo].Negritas}</b>
      </p>
      <p>{data.Torres[torre].prototipos[modelo].Descripcion}</p>
      <ul id="caracteristicas">
        {data.Torres[torre].prototipos[modelo].Caracteristicas.map((item,i) => (
          <Tile text={item.Elemento} key={"t"+i}/>
        ))}
      </ul>
    </div>
  );
};
