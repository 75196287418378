import { useEffect } from "react";

/**
 * Detecta el click fuera de un componente
 * @param  {Ref}   				ref   			Referencia al componente
 * @param  {Function}    callback     Función a ejecutar cuando suceda un click fuera
 * @return {Component} 		Función que detecta el click fue
 */
const useOutsideClick = (ref, callback) => {
	const handleClick = e => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

export default useOutsideClick;