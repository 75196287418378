import React from "react";
import { motion } from "framer-motion";
import { LeftTitle } from "../../components/LeftTitle";
import { LeftTitleVertical } from "../../components/LeftTitleVertical";
import { RightTitle } from "../../components/RightTitle";
import { RightTitleVertical } from "../../components/RightTitleVertical";
import { Breadcrumb } from "../../components/Breadcrumb";
import { GeoJsonMap } from "../../components/Mapa";
import { Fallback } from "../../components/fallback";
import { url } from "../../queries/URL";
import { getDisponibilidad } from "../../queries/Queries";
import "leaflet/dist/leaflet.css";
import "../../stylesheets/dist/disponibilidad.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'leaflet/dist/leaflet.css';

const tipos = ["No Disponible","No filtrado", "Disponible", "Separado", "Vendido"];

/**
 * Regresa el componente de la pantalla DISPONIBILIDAD
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {object}   geo     Datos del geojson
 * @param  {object}   disponibilidad  Datos de la disponibilidad
 * @return {Component}        Componente de la pantalla DISPONIBILIDAD
 */
export class DisponibilidadResponsive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      geo: undefined,
      disponibilidad: undefined,
    };
  }

  /**
   * Hace la petición y guarda la respuesta en data.
   * Lee el archivo geojson y lo guarda en geo
   * Hace la petición de la disponibilidad y la guarda en disponibilidad
   */
  componentDidMount() {
    var data = getDisponibilidad(
      this.props.match.params.desarrollo,
      localStorage.getItem("torreID"),
      localStorage.getItem("prototipoID")
    );
    data.then((res) => {
      // Información del GeoJson
      var geoJSONString;
      var rawFile = new XMLHttpRequest();
      rawFile.open(
        "GET",
        url + res.data.data.desarrollos[0].Torres[0].Geojson.url,
        false
      );
      rawFile.onreadystatechange = function () {
        try {
          if (rawFile.readyState === 4) {
            if (rawFile.status === 200 || rawFile.status === 0) {
              var allText = rawFile.responseText;
              geoJSONString = allText;
            }
          }
        } catch (error) {
          geoJSONString = {};
        }
      };
      rawFile.send(null);

      // Información de la disponibilidad
      var dataSembrado;
      rawFile.open(
        "GET",
        res.data.data.desarrollos[0].Torres[0].Disponibilidad,
        false
      );
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4) {
          if (rawFile.status === 200 || rawFile.status === 0) {
            var allText = rawFile.responseText;
            dataSembrado = allText;
          }
        }
      };
      rawFile.send(null);

      // Guarda toda la información
      this.setState({
        data: res.data.data,
        geo: geoJSONString,
        disponibilidad: JSON.parse(dataSembrado),
      });

      // Carga la fuente ya que la pantalla no tiene menú
      var name = res.data.data.desarrollos[0].identidad.Font.name.split(".")[0];
      var notoSansRegular = new FontFace(
        name,
        "url(/" + res.data.data.desarrollos[0].identidad.Font.name + ")",
        {
          style: "normal",
          weight: "400",
          display: "block",
        }
      );

      document.fonts.add(notoSansRegular);

      notoSansRegular.load();
    });

    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  updateDimensions() {
    const width = window.innerWidth >= 992 ? window.innerHeight : 400
    this.setState({ width: width })
  }


  render() {
    try {
      var data = this.state.data.desarrollos[0].Torres[0];
      return (
        <motion.div
          exit={{ x: 1920 }}
          animate={{ x: 0 }}
          // Dependiendo de donde venga la historia del navegador entra por la izquierda o derecha
          initial={{
            x:
              this.props.history.location.state.returnTo === "/modelos"
                ? 1920
                : -1920,
          }}
          transition={{ duration: 0.3 }}
        >
          {/* Aplica el font a todos los elementos */}
          <style
            dangerouslySetInnerHTML={{
              __html:
                `
  * { 
  font-family: ` +
                this.state.data.desarrollos[0].identidad.Font.name.split(
                  "."
                )[0] +
                ` !important }
`,
            }}
          />
          <div id="disponibilidad">
            <Breadcrumb
              lk={"/" + this.props.match.params.desarrollo + "/modelos"}
              tabs={[
                // eslint-disable-next-line
                this.state.data.desarrollos[0].Filtrado == "Ninguno"
                  ? localStorage.getItem("torreName")
                  : localStorage.getItem("prototipoName"),
                "Disponibilidad",
              ]}
              links={[
                "/" + this.props.match.params.desarrollo + "/modelos",
                "/" +
                  this.props.match.params.desarrollo +
                  "/" +
                  data.Nombre +
                  "/disponibilidad",
              ]}
            />
            {data.DistribucionVertical ? (<LeftTitleVertical title={data.Izquierda}/>) : (<LeftTitle title={data.Izquierda}/>) }
            {data.DistribucionVertical ? (<RightTitleVertical title={data.Derecha}/>) : (<RightTitle title={data.Derecha}/>) }
            <ul>
              {tipos.map((tipo) => (
                <li key={tipo}>
                  <div
                    className={
                      "icon " + tipo.replace(" ", "-").toLocaleLowerCase()
                    }
                  ></div>
                  {tipo}
                </li>
              ))}
            </ul>
            {/* Genera el GIS */}
            <GeoJsonMap
              data={this.state.geo}
              disponibilidadData={this.state.disponibilidad.data}
              zoomControl={this.state.data.desarrollos[0].Torres[0].ZoomControl
              }
              style={{ height: 80, width: 80 }}
              id="map"
            />
          </div>
        </motion.div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}
