import React from "react";
import { Tile } from "../components/Tile";
import "../stylesheets/dist/main.css";


/**
 * Regresa el componente del titulo, descripcion y bullets
 * @param  {String}   index     Título de la pantalla
 * @param  {String}    arr      Texto de descripción
 * @param  {Array}    items     Bullets a mostrar
 * @param  {Number}  top        Distancia del top
 * @return {Component}          Componente del titulo de las pantallas
 */
 let button=null;
export const Title = ({ title, text, items, top, topul }) => {
 //console.log('color'+localStorage.getItem("colorTerciario").includes('null'));
    if (localStorage.getItem("colorTerciario").includes('null')) {
     // console.log('verdadero');
      button = <svg
      width="86"
      height="5"
      viewBox="0 0 86 5"
      xmlns="http://www.w3.org/2000/svg"
      fill={localStorage.getItem("color")}
    >
      <rect width="86" height="5" />
    </svg>;
    } else{
      //console.log('falso');
      button = <svg
      width="86"
      height="5"
      viewBox="0 0 86 5"
      xmlns="http://www.w3.org/2000/svg"
      fill={localStorage.getItem("colorTerciario")}
    >
      <rect width="86" height="5" />
    </svg>;
    }

  return (
    <div className="title" style={{ top: top }}>
      {button}

      <h2 style={{color: localStorage.getItem("colorMenu")}}>{title}</h2>
      <div id="content">
        <p>{text}</p>
        <ul>
          {items &&
            items.map((item, index) => (
              <Tile text={item.Elemento} key={item} />
            ))}
        </ul>
      </div>
    </div>
  );
};
