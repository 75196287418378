import React from "react";
import { motion } from "framer-motion";
import { Title } from "../components/Title";
import { InfoGeneral } from "../components/InfoGeneral";
import { Planta } from "../components/Planta";
import { Vistas } from "../components/Vistas";
import { Incluido } from "../components/Incluido";
import { Multimedia } from "../components/Multimedia";
import { Breadcrumb } from "../components/Breadcrumb";
import { Fallback } from "../components/fallback";
import { getDepartamento,getBusinessIdentity, getPrototipos, getDepartamentoByIdCapi } from "../queries/Queries";
import "../stylesheets/dist/main.css";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Loading } from "../components/Loading";
import { Inicio } from "./Inicio";
/**
 * Arreglo para el match entre la tab y el nombre del switch en el panel para decidir si ocultar la tab
 */
const tabs = [
  { Nombre: "INFORMACIÓN GENERAL", PanelNombre: "InfoGeneral" },
  { Nombre: "PLANTA", PanelNombre: "Planta" },
  { Nombre: "VISTAS", PanelNombre: "Vistas" },
  { Nombre: "INCLUÍDO", PanelNombre: "Incluido" },
  { Nombre: "Multimedia", PanelNombre: "Multimedia" },
];

/**
 * Regresa el componente de la pantalla DEPARTAMENTO
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Number}   tab     Indice de la tab seleccionada
 * @return {Component}        Componente de la pantalla DEPARTAMENTO
 */
 var n=0;
 var x; 
export class Departamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      departamento:{},
      tab: 0,
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.method = new Inicio();
  }

  

  /**
   * Hace la peticion y guarda la respuesta en data.
   */
  componentDidMount() {
    if (localStorage.getItem("torreID")===undefined) {
      console.log('nada')
    }
    //console.log('this.props.match.params',this.props.match.params)
    console.log('prototipoID',localStorage.getItem("prototipoID"))
    console.log('loteID',localStorage.getItem("loteID"))

    const departamentoFetch = getDepartamentoByIdCapi(localStorage.getItem("loteID"))
    departamentoFetch.then(({data})=>{
      console.log('departamentoFetch data', data[0]);
      this.setState({
        departamento: data[0],
      });
    }).catch(e=>console.log('error departamentoFetch', e))
   
    var data = getDepartamento(
      this.props.match.params.desarrollo,
      localStorage.getItem("torreID"),
      localStorage.getItem("prototipoID"),
      //this.props.match.params.id
      localStorage.getItem("loteID")
    );
    const fetchPrototipos = getPrototipos(
      this.props.match.params.desarrollo,
      localStorage.getItem("torreID"),
      localStorage.getItem("prototipoID"),
      localStorage.getItem("loteID")
    )
    fetchPrototipos.then((data)=>{console.log('fetchPrototipos',data)}).catch((error)=>{console.error('fetchPrototipos',error)})
    var data2 = getBusinessIdentity(this.props.match.params.desarrollo);
    data.then((res) => {
      console.log('data getDepartamento',res.data.data);
      this.setState({
        data: res.data.data,
      });

      data2.then((res) => {
        //console.log(res.data.data.desarrollos[0].identidad.Background.url)
        this.setState({
          data2: res.data.data.desarrollos[0],
        });
      });
      // Carga la fuente ya que la pantalla no tiene menú
      var name = res.data.data.desarrollos[0].identidad.Font.name.split(".")[0];
      var notoSansRegular = new FontFace(
        name,
        "url(/" + res.data.data.desarrollos[0].identidad.Font.name + ")",
        {
          style: "normal",
          weight: "400",
          display: "block",
        }
      );
      document.fonts.add(notoSansRegular);
      notoSansRegular.load();
    });
  }

  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
    console.log('stopped')
   /* if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    clearInterval(x); 
    var n=0;
    setTimeout(() => {
      x = setInterval(function(){
        console.log(n);
        if (n==30) {
          location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    }, 1000);
  }

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }*/
  this.method.changeScreen();
  
  }


  render() {

    let datos = this.state.data;
    const departamento = this.state.departamento
    console.log('departamento', departamento)
    if(datos === undefined){
      return <Loading />
  }
    try {
      //console.log('prototipos departamento',departamento.prototipo)
      if(!departamento.prototipo){
        console.error('no hay prototipos',this.state.data.desarrollos[0].Torres[0]);
      }
      const id = this.props.match.params.id;
      localStorage.setItem(
        "prototipoName",
        departamento.prototipo.Nombre
      );
      return (
        <motion.div
          exit={{ x: -1920 }}
          animate={{ x: 0 }}
          initial={{ x: 1920 }}
          transition={{ duration: 0.3 }}
          onClick={this.changeScreen}
        >
          {/* Aplica el font a todos los elementos de la pantalla  */}
          <style
            dangerouslySetInnerHTML={{
              __html:
                `
  * { 
  font-family: ` +
                this.state.data.desarrollos[0].identidad.Font.name.split(
                  "."
                )[0] +
                ` !important }
`,
            }}
          />
          <div className="departamento">
          <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />
          {localStorage.getItem("disponibilidad")==="true"&&localStorage.getItem("flag")==="true"&& <Breadcrumb
              lk={
                "/" +
                this.props.match.params.desarrollo +
                "/disponibilidad"
              }
              tabs={[
                // eslint-disable-next-line
                this.state.data.desarrollos[0].Filtrado == "Ninguno"
                  ? localStorage.getItem("torreName")
                  : departamento.prototipo.Nombre
                ,
                "Disponibilidad",
                this.state.data.desarrollos[0].Deptos + " " //+departamento.Numero,
              ]}
              links={[
                "/" + this.props.match.params.desarrollo + "/disponibilidad",
                "/" +
                  this.props.match.params.desarrollo +
                  "/disponibilidad",
                "/departamento/" + id,
              ]}
              onClick={()=>{
                localStorage.setItem("prueba","1")
              }}
            />
            }

{(localStorage.getItem("disponibilidad")==="false"&&localStorage.getItem("flag")==="false")&& <Breadcrumb
              lk={
                "/" +
                this.props.match.params.desarrollo +
                "/" +
                localStorage
                  .getItem("torreName")
                  .split(" ")
                  .join("")
                  .toLocaleLowerCase() +
                "/disponibilidad"
              }
              tabs={[
                // eslint-disable-next-line
                this.state.data.desarrollos[0].Filtrado == "Ninguno"
                  ? localStorage.getItem("torreName")
                  : departamento.prototipo.Nombre
                ,
                "Disponibilidad",
                this.state.data.desarrollos[0].Deptos + " " +departamento.Numero,
              ]}
              links={[
                "/" + this.props.match.params.desarrollo + "/modelos",
                "/" +
                  this.props.match.params.desarrollo +
                  "/" +
                  localStorage
                    .getItem("torreName")
                    .split(" ")
                    .join("")
                    .toLocaleLowerCase() +
                  "/disponibilidad",
                "/departamento/" + id,
              ]}
            />
            }
            {(localStorage.getItem("disponibilidad")==="true"&&localStorage.getItem("flag")==="false")&& <Breadcrumb
              lk={
                "/" +
                this.props.match.params.desarrollo +
                "/" +
                localStorage
                  .getItem("torreName")
                  .split(" ")
                  .join("")
                  .toLocaleLowerCase() +
                "/disponibilidad"
              }
              tabs={[
                // eslint-disable-next-line
                this.state.data.desarrollos[0].Filtrado == "Ninguno"
                  ? localStorage.getItem("torreName")
                  : departamento.prototipo.Nombre
                ,
                "Disponibilidad",
                this.state.data.desarrollos[0].Deptos + " " +departamento.Numero,
              ]}
              links={[
                "/" +
                this.props.match.params.desarrollo +
                "/" +
                localStorage
                  .getItem("torreName")
                  .split(" ")
                  .join("")
                  .toLocaleLowerCase() +
                "/disponibilidad",
                "/" +
                  this.props.match.params.desarrollo +
                  "/" +
                  localStorage
                    .getItem("torreName")
                    .split(" ")
                    .join("")
                    .toLocaleLowerCase() +
                  "/disponibilidad",
                "/departamento/" + id,
              ]}
              onClick={()=>{
                localStorage.setItem("prueba","1")
              }}
            />}
            
             
            <Title
              title={ this.props.match.params.desarrollo===2|| this.props.match.params.desarrollo===3 || this.props.match.params.desarrollo==6 ?"DEPARTAMENTO " + departamento.Numero : this.state.data.desarrollos[0].Deptos + " " + departamento.Numero}
              top={120}
            />
            <div className="navigation" style={{ top: `260px` }}>
              <ul>
                {/* Muestra las tabs */}
                {tabs.map((item, index) => {
                  // En caso de que el switch no este activado, muestra la tab en la pantalla
                  if (
                    !departamento.Tabs[item.PanelNombre]
                  ) {
                    try {
                      return (
                        <li
                          key={item.Nombre}
                          onClick={(e) => this.setState({ tab: index })}
                          // eslint-disable-next-line
                          className={
                            // eslint-disable-next-line
                            this.state.tab == index ? "selected-nav" : ""
                          }
                          style={{
                            color:
                              // eslint-disable-next-line
                              this.state.tab == index
                                ? localStorage.getItem("color")
                                : "",
                            borderBottom:
                              // eslint-disable-next-line
                              this.state.tab == index
                                ? "2px solid " + localStorage.getItem("color")
                                : "",
                          }}
                        >
                          {item.Nombre}
                        </li>
                      );
                    } catch (error) {
                      console.log(error);
                      return null;
                    }
                  }
                  return null
                })}
              </ul>
              {/* Muestra el contenido para cada tab dependiendo de su index */}
              <RenderTab
                tab={this.state.tab}
                data={{...departamento.prototipo, departamentos:[departamento]}}
                dataComplete={this.state.data.desarrollos[0]}
                id={this.props.match.params.desarrollo}
                departamento={departamento}
              />{
                
              }
             
            </div>
            {/*boton*/}
            
  


          </div>
        </motion.div>
      );
    } catch (error) {
      console.warn('fallback')
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}

/**
 * Regresa el componente del contenido a mostrar dependiendo del index
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Number}   tab     Indice de la tab seleccionada
 * @return {Component}        Componente del contenido de cada tab
 */
const RenderTab = ({ tab, data, dataComplete,id, departamento }) => {
  console.log('prototipo RenderTab', data);
  switch (tab) {
    case 0:
      return <InfoGeneral data={data} dataComplete={dataComplete} id={id}/>;
      // eslint-disable-next-line
      break;
    case 1:
      return <Planta data={data} />;
      // eslint-disable-next-line
      break;
    case 2:
      return <Vistas data={departamento} />;
      // eslint-disable-next-line
      break;
    case 3:
      return <Incluido data={data} />;
      // eslint-disable-next-line
      break;
    case 4:
      return <Multimedia data={data} />;
      // eslint-disable-next-line
      break;
    default:
      return <div></div>;
      // eslint-disable-next-line
      break;
  }
};
