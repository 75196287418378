import React from "react";
import { Route, Switch } from "react-router";
import { AnimatePresence } from "framer-motion";
import { Inicio } from "./pages/Inicio.js";
import { Nosotros } from "./pages/Nosotros.js";
import { Concepto } from "./pages/Concepto.js";
import { Amenidades } from "./pages/Amenidades.js";
import { Masterplan } from "./pages/Masterplan.js";
import { Ubicacion } from "./pages/Ubicacion.js";
import { Multimedia } from "./pages/Multimedia.js";
import { Modelos } from "./pages/Modelos.js";
import { Disponibilidad } from "./pages/Disponibilidad.js";
import { Departamento } from "./pages/Departamento.js";
import { AmenidadesResponsive } from "./pages/responsive/AmenidadesResponsive";
import { MultimediaResponsive } from "./pages/responsive/MultimediaResponsive";
import { ModelosResponsive } from "./pages/responsive/ModelosResponsive";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import { DisponibilidadResponsive } from "./pages/responsive/DisponibilidadResponsive.js";
import { DepartamentoResponsive } from "./pages/responsive/DepartamentoResponsive.js";
import { DisponibilidadCompleta } from "./pages/DisponibilidadCompleta.js";
const browserHistory = createBrowserHistory();

export const App = () => (
  
  <BrowserRouter history={browserHistory}>
    <AnimatePresence>
      <Switch>
        <Route exact path="/:desarrollo" component={Inicio} />
        <Route exact path="/:desarrollo/nosotros" component={Nosotros} />
        <Route exact path="/:desarrollo/concepto" component={Concepto} />
        <Route exact path="/:desarrollo/amenidades" component={Amenidades} />
        <Route exact path="/:desarrollo/masterplan" component={Masterplan} />
        <Route exact path="/:desarrollo/ubicacion" component={Ubicacion} />
        <Route exact path="/:desarrollo/multimedia" component={Multimedia} />
        <Route exact path="/:desarrollo/modelos" component={Modelos} />
        <Route exact path="/:desarrollo/disponibilidad" component={DisponibilidadCompleta} />
        <Route exact path="/:desarrollo/AmenidadesResponsive" component={AmenidadesResponsive} />
        <Route exact path="/:desarrollo/MultimediaResponsive" component={MultimediaResponsive} />
        <Route exact path="/:desarrollo/ModelosResponsive" component={ModelosResponsive} />

        <Route
          exact
          path="/:desarrollo/:torre/disponibilidad"
          component={Disponibilidad}
        />
        <Route
          exact
          path="/:desarrollo/:torre/departamento/:id"
          component={Departamento}
        />
        <Route
          exact
          path="/:desarrollo/:torre/DisponibilidadResponsive"
          component={DisponibilidadResponsive}
        />
        <Route
          exact
          path="/:desarrollo/:torre/DepartamentoResponsive/:id"
          component={DepartamentoResponsive}
        />
      </Switch>
    </AnimatePresence>
  </BrowserRouter>
);

export default App;
