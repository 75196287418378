import React from "react";
import { GaleriaCustom } from "./GaleriaCustom";
// import { image, video, r3d, v360} from "../queries/Consts"

/**
 * Regresa el contenido de la tab de Multimedia
 * @param  {Object}   data    Información del "departamento"
 * @return {Component}  Contenido de la tab
 */
export const Multimedia = ({ data }) => {
  // Une la multimedia del prototipo con la del "departamento"
  data =
    data.Multimedia !== null
      ? data.Multimedia.concat(
          data.departamentos[0].Multimedia !== null
            ? data.departamentos[0].Multimedia
            : []
        )
      : [].concat(
          data.departamentos[0].Multimedia !== null
            ? data.departamentos[0].Multimedia
            : []
            );

    
    
  // // Filtra la multimedia por tipo y la ordena por tamaño
  // var recorrido = data.filter((item) => item.Tipo === r3d);
  // var imagenes = data.filter((item) => item.Tipo === image);
  // var vistas360 = data.filter((item) => item.Tipo === v360);
  // var videos = data.filter((item) => item.Tipo === video);

  // var dataimages = vistas360.concat(recorrido, videos, imagenes);

  try {
    return (
      <div><GaleriaCustom data={data} key={data} /></div>
    );
  } catch (error) {
    console.error(error)
    return null;
  }
};
