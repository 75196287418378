import React from "react";
import Button from "./button";
import Selector from "./Selector";
import { getDesarrollo } from "../queries/Queries";


  
const ButtonWithDropDownCmp = ({x, data2}) => {
    const sampleData = [];
    //const tamanio= data.length;
    //console.log('tamaño '+tamanio)
    //console.log(data2[0].Nombre)
    var desarrollos = JSON.parse(localStorage.getItem("selector"));
      
        //console.warn('desarrollos',desarrollos);
        if(desarrollos?.length){
          for (let index = 0; index < desarrollos.length; index++) {
              sampleData.push( desarrollos[index].Nombre);
              //console.log(localStorage.getItem("selector"));
          }
        }
   
   
    //console.log(localStorage.getItem("selector"));
    
   // console.log(sampleData)
    const sampleData2 = [];
   
    if(desarrollos?.length){
      for (let index = 0; index <desarrollos.length; index++) {
        sampleData2.push(  desarrollos[index].id);
        
    } 
    }
    
    
  const [open, setOpen] = React.useState(false);
  const drop = React.useRef(null);
  
  function handleClick(e) {
    
    if (!e.target.closest("#dropdown") && open) {
      setOpen(false);
    }
  }
 
  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
  return (
    <div
    id="dropdown"
      className="drop"
      ref={drop}
      key={"divbotonselector"}
    >
      
      <Button key={"boton"} onClick={() => {setOpen(open => !open);clearInterval(x)}} />
      {(open && sampleData?.length ) && <Selector key={sampleData[0]+"selector"} llave={"selector"} data={sampleData} data2={sampleData2} setOpen={setOpen} /> }
    </div>
  );
};

export default ButtonWithDropDownCmp;
