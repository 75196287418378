
import React from "react";
import { Drawer } from "../../components/Drawer.js";
import { Title } from "../../components/Title.js";
import { Torres } from "../../components/ModeloTorres.js";
import { Fallback } from "../../components/fallback";
import { getModelos } from "../../queries/Queries.js";
import "../../stylesheets/dist/modelos.css";
import "../../stylesheets/dist/main.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

/**
 * Regresa el componente de la pantalla MODELOS
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Number}   torre   Torre seleccionada
 * @param  {Number}   modelo  Prototipo(Recaramasa) seleccionada
 * @param  {Number}   tab     Tab seleccionadada
 * @param   {Array}   images  Arreglo donde guardamos las imagenes que le pasamos al Slider
 * @return {Component}        Componente de la pantalla MODELOS
 */
export class ModelosResponsive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      torre: localStorage.getItem("torre") ? localStorage.getItem("torre") : 0,
      modelo: localStorage.getItem("prototipo")
        ? localStorage.getItem("prototipo")
        : 0,
      tab: localStorage.getItem("tab") ? localStorage.getItem("tab") : 0,
      images: [],
    };

    this.handleStorage = this.handleStorage.bind(this);
    this.handleTorre = this.handleTorre.bind(this);
    this.handleModelo = this.handleModelo.bind(this);
    this.handleTab = this.handleTab.bind(this);
  }

  /**
   * Hace la petición y guarda la respuesta en data.
   */
  componentDidMount() {
    console.log('render ModelosResponsive');
    var data = getModelos(this.props.match.params.desarrollo);

    data.then((res) => {
      console.log(res.data.data);

      this.setState({
        data: res.data.data,
      });
    });
  }

  // handleScroll() {
  //   document.getElementById("info-overflow").scrollTop = 0;
  // }

  /**
   * Guarda la informacíon necesaria para que la siguiente pantalla tenga los valores solicitados en el localStorage
   */
  handleStorage() {
    try {
      localStorage.setItem("torre", this.state.torre);
      localStorage.setItem("prototipo", this.state.modelo);
      localStorage.setItem("tab", this.state.tab);
      localStorage.setItem(
        "torreID",
        this.state.data.desarrollos[0].Torres[this.state.torre].id
      );
      localStorage.setItem(
        "torreName",
        this.state.data.desarrollos[0].Torres[this.state.torre].Nombre
      );

      if (this.state.data.desarrollos[0].Filtrado === "Modelos") {
        console.log(this.state.data.desarrollos[0]);
        localStorage.setItem(
          "prototipoID",
          this.state.data.desarrollos[0].Torres[this.state.torre].prototipos[
            this.state.modelo
          ].id
        );
        localStorage.setItem(
          "prototipoName",
          this.state.data.desarrollos[0].Torres[this.state.torre].prototipos[
            this.state.modelo
          ].Nombre
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Maneja el cambio de torre, reestableciendo los valores del modelo y de la tab a los primeros
   */
  handleTorre(torre) {
    this.setState({
      torre: torre,
      modelo: 0,
      tab: 0,
    });
  }

  /**
   * Maneja el cambio de modelo, reestableciendo el valor de la tab al inicio
   */
  handleModelo(modelo, id) {
    this.setState({
      modelo: modelo,
      tab: 0,
    });
  }

  /**
   * Cambia la tab
   */
  handleTab(tab) {
    this.setState({
      tab: tab,
    });
  }

  render() {
    try {
      var data = this.state.data.desarrollos[0];
      return (
        <div className="modelos">
          
          <Title title={data.Modelos} />
          <Torres
            link={this.props}
            data={data}
            images={this.state.images}
            torre={this.state.torre}
            modelo={this.state.modelo}
            tab={this.state.tab}
            handleTorre={this.handleTorre}
            handleModelo={this.handleModelo}
            handleTab={this.handleTab}
            handleStorage={this.handleStorage}
            handleImagesAux={this.handleImagesAux}
          />
        </div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}