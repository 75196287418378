import React from "react";
import { Drawer } from "../components/Drawer.js";
import { Title } from "../components/Title.js";
import { Torres } from "../components/ModeloTorres.js";
import { Fallback } from "../components/fallback";
import { getModelos,getBusinessIdentity } from "../queries/Queries.js";
import "../stylesheets/dist/modelos.css";
import "../stylesheets/dist/main.css";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Loading } from "../components/Loading.js";
import { url,urlRestAPI } from "../queries/URL";
import { Refresh } from "../components/Refresh.js";
import { Inicio } from "./Inicio.js";
/**
 * Regresa el componente de la pantalla MODELOS
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {Number}   torre   Torre seleccionada
 * @param  {Number}   modelo  Prototipo(Recaramasa) seleccionada
 * @param  {Number}   tab     Tab seleccionadada
 * @param   {Array}   images  Arreglo donde guardamos las imagenes que le pasamos al Slider
 * @return {Component}        Componente de la pantalla MODELOS
 */
 var n=0;
 var x; 
export class Modelos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      refresh:undefined,
      torre: localStorage.getItem("torre") ? localStorage.getItem("torre") : 0,
      modelo: localStorage.getItem("prototipo")
        ? localStorage.getItem("prototipo")
        : 0,
      tab: localStorage.getItem("tab") ? localStorage.getItem("tab") : 0,
      images: [],
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.handleStorage = this.handleStorage.bind(this);
    this.handleTorre = this.handleTorre.bind(this);
    this.handleModelo = this.handleModelo.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.method = new Inicio();
  }

  /**
   * Hace la petición y guarda la respuesta en data.
   */
  componentDidMount() {
    console.log('render Modelos');
    localStorage.setItem("disponibilidad","false")
    localStorage.setItem("flag","false")
    //var data = getModelos(this.props.match.params.desarrollo);
    //var data2 = getBusinessIdentity(this.props.match.params.desarrollo);

    console.log('torre state',this.state);
    // fetch(`${urlRestAPI}/torres/${}`)
   
   
    const requestOptions = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/modelos",requestOptions)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       console.log('data state',data);
       this.setState({
        data: data,
      });
    }
      });
   /* data.then((res) => {
      this.setState({
        data: res.data.data,
      });
    });
*/
    const requestOptions2 = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo})
      };
        fetch(urlRestAPI+"/menu",requestOptions2)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       this.setState({
        data2: data.desarrollos[0],
      });
    }
      });
  /*  data2.then((res) => {
      //console.log(res.data.data.desarrollos[0].identidad.Background.url)
      this.setState({
        data2: res.data.data.desarrollos[0],
      });
    });*/
  }

  // handleScroll() {
  //   document.getElementById("info-overflow").scrollTop = 0;
  // }

  /**
   * Guarda la informacíon necesaria para que la siguiente pantalla tenga los valores solicitados en el localStorage
   */
  handleStorage() {
    try {
      localStorage.setItem("torre", this.state.torre);
      localStorage.setItem("prototipo", this.state.modelo);
      localStorage.setItem("tab", this.state.tab);
      localStorage.setItem(
        "torreID",
        this.state.data.desarrollos[0].Torres[this.state.torre].id
      );
      localStorage.setItem(
        "torreName",
        this.state.data.desarrollos[0].Torres[this.state.torre].Nombre
      );

      if (this.state.data.desarrollos[0].Filtrado === "Modelos") {
        localStorage.setItem(
          "prototipoID",
          this.state.data.desarrollos[0].Torres[this.state.torre].prototipos[
            this.state.modelo
          ].id
        );
        localStorage.setItem(
          "prototipoName",
          this.state.data.desarrollos[0].Torres[this.state.torre].prototipos[
            this.state.modelo
          ].Nombre
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Maneja el cambio de torre, reestableciendo los valores del modelo y de la tab a los primeros
   */
  handleTorre(torre) {
    console.log('change torre',torre);
    this.setState({
      torre: torre,
      modelo: 0,
      tab: 0,
    });
  }

  /**
   * Maneja el cambio de modelo, reestableciendo el valor de la tab al inicio
   */
  handleModelo(modelo, id) {
    this.setState({
      modelo: modelo,
      tab: 0,
    });
  }

  /**
   * Cambia la tab
   */
  handleTab(tab) {
    this.setState({
      tab: tab,
    });
  }

  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
   /* console.log('stopped')
    if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    clearInterval(x); 
    var n=0;
    setTimeout(() => {
      x = setInterval(function(){
        console.log(n);
        if (n==30) {
          location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    }, 1000);
  }

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }*/
  this.method.changeScreen();
  
  }

  render() {
    let datos = this.state.data;
    let refresh =this.state.refresh;
    console.log(refresh);
    if (refresh == false) {
      return <Refresh />
    }
    if(datos === undefined){
        return <Loading />
    }
    try {
      var data = this.state.data.desarrollos[0];
      localStorage.setItem("disponibilidad",false)
      return (
        <div onClick={this.changeScreen} className="modelos">
          <Drawer data={this.state.data2}/>
          <ButtonWithDropDown data={this.state.data2} data2={this.state.data2} />
          <Title title={data.Modelos} />
          <Torres
            link={this.props}
            data={data}
            images={this.state.images}
            torre={this.state.torre}
            modelo={this.state.modelo}
            tab={this.state.tab}
            handleTorre={this.handleTorre}
            handleModelo={this.handleModelo}
            handleTab={this.handleTab}
            handleStorage={this.handleStorage}
            handleImagesAux={this.handleImagesAux}
            
          />
        </div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}
