import React from "react";
import { motion } from "framer-motion";
import { LeftTitle } from "../components/LeftTitle";
import { LeftTitleVertical } from "../components/LeftTitleVertical";
import { RightTitle } from "../components/RightTitle";
import { RightTitleVertical } from "../components/RightTitleVertical";
import { Breadcrumb } from "../components/Breadcrumb";
import { GeoJsonMap } from "../components/Mapa";
import { Fallback } from "../components/fallback";
import { url,urlRestAPI } from "../queries/URL";
import { getDisponibilidad } from "../queries/Queries";
import "leaflet/dist/leaflet.css";
import "../stylesheets/dist/disponibilidad.css";
import ButtonWithDropDown from "../components/buttonDropDown";
import { Loading } from "../components/Loading";
import { Refresh } from "../components/Refresh";
import { Inicio } from "./Inicio";
import { GeoJsonMapBack } from "../components/MapaBack";


/**
 * Regresa el componente de la pantalla DISPONIBILIDAD
 * @param  {object}   data    Guarda la información recibida por la petición
 * @param  {object}   geo     Datos del geojson
 * @param  {object}   disponibilidad  Datos de la disponibilidad
 * @return {Component}        Componente de la pantalla DISPONIBILIDAD
 */
 var n=0;
 var x; 
export class Disponibilidad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      data2: undefined,
      geo: undefined,
      disponibilidad: undefined,
      refresh:undefined,
      torre:{}
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.method = new Inicio();
  }

  /**
   * Hace la petición y guarda la respuesta en data.
   * Lee el archivo geojson y lo guarda en geo
   * Hace la petición de la disponibilidad y la guarda en disponibilidad
   */
  componentDidMount() {
    

   
    const requestOptions = {
      method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ desarrollo:  this.props.match.params.desarrollo,torre:localStorage.getItem("torreID")})
      };
        fetch(urlRestAPI+"/gis",requestOptions)
      .then((response) => {
        console.log(response.status);
        if(response.status===200) {
          //console.log(response.json())
          var data= response.json();
          console.log(data)
          return data;
        } else {
         return false;
        }   
      })
      .then((recurso) => {
        console.log(recurso)
        if (recurso===false) {
          this.setState({
            refresh:recurso,
          });
          
        }else{
       var data= recurso;
       // Información del GeoJson
      var geoJSONString;
      var rawFile = new XMLHttpRequest();
      rawFile.open(
        "GET",
        url + data.desarrollos[0].Torres[0].Geojson.url,
        false
      );
      rawFile.onreadystatechange = function () {
        try {
          if (rawFile.readyState === 4) {
            if (rawFile.status === 200 || rawFile.status === 0) {
              var allText = rawFile.responseText;
              geoJSONString = allText;
            }
          }
        } catch (error) {
          geoJSONString = {};
        }
      };
      rawFile.send(null);

      // Información de la disponibilidad
      var dataSembrado;
      rawFile.open(
        "GET",
        data.desarrollos[0].Torres[0].Disponibilidad,
        false
      );
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4) {
          if (rawFile.status === 200 || rawFile.status === 0) {
            var allText = rawFile.responseText;
            dataSembrado = allText;
          }
        }
      };
      rawFile.send(null);

      // Guarda toda la información
      console.log('save State');
      const torreId = localStorage.getItem("torreID")
      console.log('url torre',`${url}/torres/${torreId}`);
      fetch(`${url}/torres/${torreId}`)
      .then(dataFetch=>dataFetch.json())
      .then(dataTorre=>{
        console.log('torre dataTorre',dataTorre)
        // this.setState({
          // })
          this.setState({
            data: data,
            geo: geoJSONString,
            disponibilidad: JSON.parse(dataSembrado),
            torre:dataTorre
        });
      })
      

      // Carga la fuente ya que la pantalla no tiene menú
      var name = data.desarrollos[0].identidad.Font.name.split(".")[0];
      var notoSansRegular = new FontFace(
        name,
        "url(/" + data.desarrollos[0].identidad.Font.name + ")",
        {
          style: "normal",
          weight: "400",
          display: "block",
        }
      );

      document.fonts.add(notoSansRegular);

      notoSansRegular.load();
        }
      });

    
    

    /*var data = getDisponibilidad(
      this.props.match.params.desarrollo,
      localStorage.getItem("torreID"),
      localStorage.getItem("prototipoID")
    );*/
    /*data.then((res) => {
      // Información del GeoJson
      var geoJSONString;
      var rawFile = new XMLHttpRequest();
      rawFile.open(
        "GET",
        url + res.data.data.desarrollos[0].Torres[0].Geojson.url,
        false
      );
      rawFile.onreadystatechange = function () {
        try {
          if (rawFile.readyState === 4) {
            if (rawFile.status === 200 || rawFile.status === 0) {
              var allText = rawFile.responseText;
              geoJSONString = allText;
            }
          }
        } catch (error) {
          geoJSONString = {};
        }
      };
      rawFile.send(null);

      // Información de la disponibilidad
      var dataSembrado;
      rawFile.open(
        "GET",
        res.data.data.desarrollos[0].Torres[0].Disponibilidad,
        false
      );
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4) {
          if (rawFile.status === 200 || rawFile.status === 0) {
            var allText = rawFile.responseText;
            dataSembrado = allText;
          }
        }
      };
      rawFile.send(null);

      // Guarda toda la información
      this.setState({
        data: res.data.data,
        geo: geoJSONString,
        disponibilidad: JSON.parse(dataSembrado),
      });

      // Carga la fuente ya que la pantalla no tiene menú
      var name = res.data.data.desarrollos[0].identidad.Font.name.split(".")[0];
      var notoSansRegular = new FontFace(
        name,
        "url(/" + res.data.data.desarrollos[0].identidad.Font.name + ")",
        {
          style: "normal",
          weight: "400",
          display: "block",
        }
      );

      document.fonts.add(notoSansRegular);

      notoSansRegular.load();
    });*/
  }

  changeScreen() {
    //let history = useHistory();
    //var initial=this.state.initial;
   /* console.log('stopped')
    if (this.props.match.params.desarrollo!==localStorage.getItem('idDefault')) {
    clearInterval(x); 
    var n=0;
    setTimeout(() => {
      x = setInterval(function(){
        console.log(n);
        if (n==30) {
          location.href = "https://carzapantallas.dev.m22.mx/"+localStorage.getItem('idDefault');
        }
        n++;
      },1000);
    }, 1000);
  }

    //clearTimeout( initial )
    //this.invocation(initial)
   
  //if(n==8){ clearInterval(x); }*/
  this.method.changeScreen();
  
  }
  
  render() {
    
    let datos = this.state.data;
    let refresh =this.state.refresh;
    console.log(refresh);
    if (refresh == false) {
      return <Refresh />
    }
    if(datos === undefined){
      return <Loading />
  }
let selector = <ButtonWithDropDown key={"botonlista"} data={this.state.data2} data2={this.state.data2} />;
    try {
      var data = this.state.data.desarrollos[0].Torres[0];
      const prueba = [];
      if (this.state.data.desarrollos[0].Torres[0].NoDisponible) {
        prueba.push("No Disponible");
      }
      if (this.state.data.desarrollos[0].Torres[0].Disponible) {
        prueba.push("Disponible");
      }
      if (this.state.data.desarrollos[0].Torres[0].NoFiltrado) {
        prueba.push("No Filtrado");
      }
      if (this.state.data.desarrollos[0].Torres[0].Separado) {
        prueba.push("Separado");
      }
      if (this.state.data.desarrollos[0].Torres[0].Vendido) {
        prueba.push("Vendido");
      }
      return (
        <div onClick={this.changeScreen} key={"divsel"} >
        <div className="selectorDispo">{selector}</div>
        
        <div> 
        <motion.div
          exit={{ x: 1920 }}
          animate={{ x: 0 }}
          // Dependiendo de donde venga la historia del navegador entra por la izquierda o derecha
          /*initial={{
            x:
              this.props.history.location.state.returnTo === "/modelos"
                ? 1920
                : -1920,
          }}*/
          initial={{ x: 1920 }}
          transition={{ duration: 0.3 }}
        >
          {/* Aplica el font a todos los elementos */}
          <style
            dangerouslySetInnerHTML={{
              __html:
                `
  * { 
  font-family: ` +
                this.state.data.desarrollos[0].identidad.Font.name.split(
                  "."
                )[0] +
                ` !important }
`,
            }}
          />
          
          <div className="" id="disponibilidad">
                { console.log(this.props.match.params)}
           {localStorage.getItem("disponibilidad")==="true"&&localStorage.getItem("flag")==="false"&& <Breadcrumb
              lk={"/" + this.props.match.params.desarrollo + "/"+localStorage.getItem("torreName").toLowerCase()+"/departamento/"+localStorage.getItem("loteID")}
              tabs={[
                // eslint-disable-next-line
                this.state.data.desarrollos[0].Filtrado == "Ninguno"
                  ? localStorage.getItem("torreName")
                  : localStorage.getItem("prototipoName"),
                "Disponibilidad",
              ]}
              links={[
                "/" + this.props.match.params.desarrollo + "/disponibilidad",
                "/" +
                  this.props.match.params.desarrollo +
                  "/" +
                  data.Nombre +
                  "/disponibilidad",
              ]}
              onClick={()=>{
                localStorage.setItem("flag","true")
                localStorage.setItem("dispo","true")
              }}
            />
            }

{localStorage.getItem("disponibilidad")==="false"&&localStorage.getItem("flag")==="false"&& <Breadcrumb
              lk={"/" + this.props.match.params.desarrollo + "/modelos"}
              tabs={[
                // eslint-disable-next-line
                this.state.data.desarrollos[0].Filtrado == "Ninguno"
                  ? localStorage.getItem("torreName")
                  : localStorage.getItem("prototipoName"),
                "Disponibilidad",
              ]}
              links={[
                "/" + this.props.match.params.desarrollo + "/modelos",
                "/" +
                  this.props.match.params.desarrollo +
                  "/" +
                  data.Nombre +
                  "/disponibilidad",
              ]}
              onClick={()=>{
                localStorage.setItem("dispo","true")
              }}
            />
            }
            {data.DistribucionVertical ? (<LeftTitleVertical title={data.Izquierda}/>) : (<LeftTitle title={data.Izquierda}/>) }
            {data.DistribucionVertical ? (<RightTitleVertical title={data.Derecha}/>) : (<RightTitle title={data.Derecha}/>) }
            <ul>
              {prueba.map((tipo,i) =>{
                if(this.props.match.params.desarrollo == "1" && tipo == "No Disponible" && data.Nombre == "SANTA HELENA"){
                  var classNameString = "icon " + tipo.replace(" ", "-").toLocaleLowerCase() + "-1";
                }else{
                  var classNameString = "icon " + tipo.replace(" ", "-").toLocaleLowerCase();
                }
                return <li key={tipo}>
                  <div
                      className={
                        classNameString
                      }
                  ></div>
                  {tipo}
                
                </li>
              })}
            </ul>
            {/* Genera el GIS */}
            {console.log('GeoJsonMap torre',this.state.torre)}
            {this.state.torre.MapaTerreno ? <GeoJsonMapBack
                        data={this.state.geo}
                        disponibilidadData={this.state.disponibilidad.data}
                        zoomControl={this.state.data.desarrollos[0].Torres[0].ZoomControl}
                        id="map"
                        datos={this.state.data.desarrollos[0]}
                        idDesarrollo={this.props.match.params.desarrollo}
                        atributionMap = {true}
                      /> 
            : <GeoJsonMap
            data={this.state.geo}
            disponibilidadData={this.state.disponibilidad.data}
            zoomControl={this.state.data.desarrollos[0].Torres[0].ZoomControl}
            id="map"
          />  
          }
          
          </div>
        </motion.div>
        </div>
        </div>
      );
    } catch (error) {
      console.log(this.state.data);
      console.error(error);
      if (this.state.data === undefined) {
        return null;
      }
      return <Fallback />;
    }
  }
}
